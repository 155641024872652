import React from 'react'

const TimerWeek1 = () => {
  return (
<section className="assestmenttimwe_001">
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-12 col-md-12">
              <ul className="timer_step ">
                <li className="active">
                  <p>Ankle Scraping</p>
                </li>
                <li>
                  <p>Ankle Scraping</p>
                </li>
                <li>
                  <p>Ankle Scraping</p>
                </li>
                <li>
                  <p>Ankle Scraping</p>
                </li>
                <li>
                  <p>Ankle Scraping</p>
                </li>
                <li>
                  <p>Ankle Scraping</p>
                </li>
                <li>
                  <p>Ankle Scraping</p>
                </li>
                <li>
                  <p>Ankle Scraping</p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
  )
}

export default TimerWeek1