import React from 'react'


import img4 from '../../images/img4.png'
const Home6 = () => {
  return (
    <section className="home_006">
    <div className="container h-100">
        <div className="row align-items-center h-100">

            <div className="col-12 col-lg-5 col-md-5 my-auto or2">
                <div className="">
                    <h2>The muscles you use most often get stronger and

                        <span>dominant</span>
                    </h2>
                </div>
            </div>
            <div className="col-12 col-lg-6 col-md-6 or1">
                <img src={img4} alt="img4" className="img-fluid show_mob" />
            </div>
        </div>
    </div>
</section>
  )
}

export default Home6