import React from 'react'
import { Link } from "react-router-dom";
import logo from "../../images/logo.png";

const FooterTop = () => {
  return (
    <footer className="footer">
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-4 col-md-4">
              <div className="bx_footer">
                <div className="footer_logo">
                  <Link to="/">
                    {" "}
                    <img src={logo} alt="logo" className="img-fluid" />
                  </Link>
                </div>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ac
                  vestibulum et quis eu, magna varius.
                </p>
                <ul className="cards">
                  <li>
                    <div>
                      <Link to="#">Facebook</Link>
                    </div>
                  </li>
                  <li>
                    <div>
                      <Link to="#">Twitter</Link>
                    </div>
                  </li>
                  <li>
                    <div>
                      <Link to="#">Instagram</Link>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-6 col-lg-4 col-md-4">
              <h6>Sitemap </h6>
              <ul className="menu_footer two">
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>
                  <Link to="#">Pain Trigger Points </Link>
                </li>
                <li>
                  <Link to="#">About Us </Link>
                </li>
                <li>
                  <Link to="#">How it Works </Link>
                </li>
                <li>
                  <Link to="/welcome">Programs </Link>
                </li>
                <li>
                  <Link to="#">Education </Link>
                </li>
              </ul>
            </div>
            <div className="col-6 col-lg-2 col-md-2">
              <h6> Company </h6>
              <ul className="menu_footer">
                <li>
                  <Link to="#">About Us </Link>
                </li>
                <li>
                  <Link to="#">Who We Are</Link>
                </li>
                <li>
                  <Link to="#">What We Do </Link>
                </li>
                <li>
                  <Link to="#">Contact Us </Link>
                </li>
              </ul>
            </div>
            <div className="col-12 col-lg-2 col-md-2">
              <h6>Contact Details</h6>
              <div className="help">
                <p>
                  <a href="mailto:info@bodysolutions.com">
                    info@bodysolutions.com
                  </a>
                </p>

                <p>28 Cambridge Avenue San Fransisco 3426</p>
                <p>
                  <a href="tel:(758) 937 - 2694">(758) 937 - 2694</a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </footer>
  )
}

export default FooterTop