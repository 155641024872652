import React from "react";
import Footer from "../../Layout/Footer";
import Header from "../../Layout/Header";
import Welcome1 from "./Welcome1";
import TimerWeek1 from "./TimerWeek1";
import Welcome2 from "./Welcome2";
import Test1Week1 from "./Test1Week1";

const AssessmentTimer1 = () => {
  return (
    <>
    <Header />
      <Welcome1 />
      <TimerWeek1 />
      <Welcome2 />
      <Test1Week1 />
      <Footer />
    </>
  );
};

export default AssessmentTimer1;
