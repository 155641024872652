import React, {useEffect, useState} from 'react'
import {Link} from 'react-router-dom'
import {Swiper, SwiperSlide} from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import profile from '../../images/dummy.jpg'
import profile1 from "../../images/Rectangle 18140.png";
import profile2 from "../../images/Rectangle 18142.png";
import profile3 from "../../images/Rectangle 18142 (1).png";
// import required modules
import {Pagination} from "swiper";
import {Col} from "react-bootstrap";
import {connect} from "react-redux";
import {getTestimonials} from "../../../actions/user_program";


const Home4 = ({getTestimonials, testimonial}) => {
    const [data, setData] = useState([])

    useEffect(() => {
        getTestimonials()
    }, [])

    useEffect(() => {
        if (testimonial) {
            setData(testimonial?.data)
        }
    }, [testimonial])

    const testimonialsPerSlide = 3;
    const loopCount = Math.ceil(data.length / testimonialsPerSlide);




    return (
        <section className="home_004">
            <div className="container-fluid  ">
                <div className="row ">
                    <div className="col-12 col-lg-12 col-md-12 m-auto  ">
                        <div className="text-center">
                            <h2>Testimonials
                            </h2>
                            <h5>
                                What our client are saying
                            </h5>
                        </div>
                    </div>
                </div>
                <div className="row content-border">
                    <div className="col-12 col-lg-12 col-md-12 m-auto  ">
                        <Swiper
                            spaceBetween={30}
                            pagination={{
                                clickable: true,
                            }}
                            modules={[Pagination]}
                            className="mySwiper"
                        >
                            {Array.from({ length: loopCount }, (_, i) => (

                                                    <SwiperSlide key={i}>
                                                        <div className="row ">
                                    {data
                                        .slice(i * testimonialsPerSlide, (i + 1) * testimonialsPerSlide)
                                        .map((testimonial) => (
                                                                <div className=" col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12  my-auto mb-md-4 mb-sm-4 mb-xs-4 text-center "key={testimonial.id}>
                                                                    <Link to="/testimonials">
                                                                        <div className="profile">
                                                                            <div className="img-cover">
                                                                                <img src={testimonial.image} alt="" className="img-fluid"/>
                                                                            </div>
                                                                            <div className="slide-content">
                                                                                <p>{testimonial.description.substring(0, 180)}...
                                                                                      </p>
                                                                                <div className="profile-info">
                                                                                    <h6>{testimonial.name}</h6>
                                                                                    <p>
                                                                                        {testimonial.title}
                                                                                    </p>
                                                                                </div>
                                                                            </div>

                                                                        </div>
                                                                    </Link>
                                                                </div>
                                        ))}
                                                        </div></SwiperSlide>))
                                    }
                        </Swiper>

                    </div>
                </div>
            </div>
        </section>
    )
}
const mapStateToProps = state => {

    return {
        testimonial: state.programReducer.testimonial
    }
}
export default connect(mapStateToProps, {getTestimonials})(Home4)
