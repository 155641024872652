import React from "react";
import Footer from "../../Layout/Footer";
import Header from "../../Layout/Header";
import Welcome1 from './Welcome1'
import Assessment1 from './Assessment1'
import Welcome2 from './Welcome2'
import Assessment2 from './Assessment2'
import Assessment3 from './Assessment3'

const AssessmentDay1 = () => {
  return (
    <>
      <Header />
    <Welcome1 />
    <Assessment1 />
    <Welcome2 />
    <Assessment2 />
    <Assessment3 />
      <Footer />
    </>
  );
};

export default AssessmentDay1;
