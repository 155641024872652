import React from 'react'
import {Link} from 'react-router-dom'
import { useState } from "react";
import logo from '../../images/logo.png'

const Header = () => {
const [mobileMenu, setMobileMenu] = useState(false);
  console.log("state", mobileMenu);

  const onMenuClick = () => {
    // setMobileMenu(true);
    console.log("state", mobileMenu)

    // if (mobileMenu) {
    // //   document.querySelector(".hide_mobile").classList.add("show_mobile_menu");
    //   document.querySelector("body").classList.add("overflow");
    // }
  };

  const onCloseClick = () => {
  setMobileMenu(false)
  }
  return (
     <header className="header">
    <div className="container">
        <div className="row align-items-center">
            <div className="col-12 col-lg-2 col-md-2 ">
                <div className="logo">
                    <Link to="/"> <img src={logo} alt="logo" className="img-fluid" /></Link>
                </div>
                <span className="icon_mobile"  onClick={() => {setMobileMenu(!mobileMenu);onMenuClick();}} ><i className="fas fa-bars"></i></span>
            </div>
            <div className={`col-12 col-lg-10 col-md-10 text-right hide_mobile ${mobileMenu ? "show_mobile_menu" : ""}`}>
<span className="show_mobile close1" onClick={()=>onCloseClick()}>
                        <i className="far fa-times-circle"></i>
                    </span>
                <div className="main_head">
                    <ul className="menu_top right_left">
                        <li><Link to="/" className="h_login"> Home</Link></li>
                        <li><Link to="#" className="h_login"> About Us </Link></li>
                        <li><Link to="/welcome" className="h_login"> programs</Link></li>
                        <li><Link to="#" className="h_login"> Education</Link></li>
                        <li><Link to="#" className="h_login"> Pain Trigger points</Link></li>
                        <li><Link to="/testimonials" className="h_login"> Testimonials</Link></li>
                    </ul>
                    <ul className="menu_top right_menu">
                        <li><Link to="/login" className="h_login"> Sign In</Link></li>

                        <li><Link to="/signup" className="btn-1">Sign
                                Up</Link>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</header>
  )
}

export default Header