import api from '../utils/api';
import {setAlert} from './alert';
import {
    AUTH_ERROR,
    LOGIN_FAIL,
    LOGIN_REQUEST,
    LOGIN_SUCCESS,
    LOGIN_UNVERIFIED,
    LOGOUT,
    LOGOUT_FAILURE,
    LOGOUT_REQUEST,
    OTP_FAILURE, OTP_REQUEST,
    OTP_SUCCESS,
    PASSWORD_RESET_FAILURE, PASSWORD_RESET_REQUEST,
    PASSWORD_RESET_SUCCESS,
    REGISTER_FAIL,
    REGISTER_REQUEST,
    REGISTER_SUCCESS,
    RESEND_OTP_FAILURE,
    RESEND_OTP_REQUEST,
    RESEND_OTP_SUCCESS,
    USER_LOADED,
    VERIFY_OTP_FAILURE,
    VERIFY_OTP_REQUEST,
    VERIFY_OTP_SUCCESS
} from './types'
import React from "react";
import history from '../history'
import setAuthToken from "../utils/setAuthToken";

/*
  NOTE: we don't need a config object for axios as the
 default headers in axios are already Content-Type: application/json
 also axios stringifies and parses JSON for you, so no need for 
 JSON.stringify or JSON.parse
*/


//Load User
// export  const loadUser = () => async dispatch => {
//
//     try{
//         let token = localStorage.getItem('token')
//         // console.log(token)
//         const res = await api.post('/me',
//             {},{
//                 headers: {
//                     Authorization: 'Bearer ' + token
//                 }
//             }
//             )
//         dispatch({
//             type: USER_LOADED,
//             payload: res.data.data
//         })
//         }
//
//     catch (err) {
//          dispatch({
//              type: AUTH_ERROR
//          })
//     }
// }

//Reset password
export const reset = (payload) => async (dispatch) => {
    try {
        dispatch({
            type: PASSWORD_RESET_REQUEST,
            payload: null
        });

        const res = await api.post('/reset-password', payload);
        dispatch({
            type: PASSWORD_RESET_SUCCESS,
            payload: res.data.data
        });
        let msg = res.data.message
        dispatch(setAlert(msg, 'success'))

    } catch (err) {
        const msg = err.response.data.message;
        dispatch(setAlert(msg, 'danger'))

        dispatch({
            type: PASSWORD_RESET_FAILURE
        });
    }

}

//Send OTP from forgot password screen
export const sendOTP = (email) => async (dispatch) => {
    try {
        dispatch({
            type: OTP_REQUEST,
            payload: null
        });
        const res = await api.post('/forgot-password', {email});
        dispatch({
            type: OTP_SUCCESS,
            payload: res.data.data
        })
        let msg = res.data.message
        dispatch(setAlert(msg, 'success'))
        ;
    } catch (err) {
        const msg = err.response.data.message;
        dispatch(setAlert(msg, 'danger'))

        dispatch({
            type: OTP_FAILURE
        });
    }

}

//Register User
export const registerUser = (payload) => async (dispatch) => {

    payload.device_type = 'web'
    payload.device_token = 'ABC'
    payload.role_id = '2'
    let json = JSON.stringify(payload)
    try {
        dispatch({
            type: REGISTER_REQUEST,
            payload: null
        });
        const res = await api.post('/register', json);
        dispatch({
            type: REGISTER_SUCCESS,
            payload: res.data.data
        });
        let msg = res.data.message
        dispatch(setAlert(msg, 'success'))

    } catch (err) {
        dispatch({
            type: REGISTER_FAIL
        });
        const msg = err.response.data.message;
        dispatch(setAlert(msg, 'danger'))
    }
};

// Login User
export const login = (email, password) => async (dispatch) => {
    const body = {email, password}
    body.device_type = 'web'
    body.device_token = 'ABC'
    body.role_id = '2'
    try {

        dispatch({
            type: LOGIN_REQUEST,
            payload: null
        });
        const res = await api.post('/login', body);


        let verified = res.data.data.user.is_verified
        
        if (verified === 0) {
            dispatch({
                type: LOGIN_UNVERIFIED,
                payload: res.data.data
            });
            // history.push("/verify-email")
        } else {
            //I commented this since navigate is an instance of useNavigate which
            // is a hook and it can only be used in function component
            // navigate("/verify-email", {replace: true}
            // Navigate is not a hook; its a react router component-but isn't working here
            // return <Navigate to="/verify-email" />
            // dispatch(browserHistory.push("/verify-email"))
            dispatch({
                type: LOGIN_SUCCESS,
                payload: res.data.data
            });
            let msg = res.data.message
            dispatch(setAlert(msg, 'success'))
            // dispatch(gotoWelcome())
        }
    } catch (err) {
        console.log(err)
        const msg = err.response.data.message;

        dispatch(setAlert(msg, 'danger'))

        dispatch({
            type: LOGIN_FAIL
        });
    }
};

// Logout
export const logout = () => async (dispatch) => {
    try {
        dispatch({type: LOGOUT_REQUEST})
        let token = localStorage.getItem('token')
        const res = await api.post('/logout',
            {}, {
                headers: {
                    Authorization: 'Bearer ' + token
                }
            });
        dispatch({type: LOGOUT})

        let msg = res.data.message
        dispatch(setAlert(msg, 'success'))
    } catch (err) {
        dispatch({type: LOGOUT_FAILURE})
        const msg = err.response.data.message;

        dispatch(setAlert(msg, 'danger'))
    }
}

//verifyOTP
export const verifyOTP = (payload) => async (dispatch) => {
    try {
        dispatch({type: VERIFY_OTP_REQUEST})
        const res = await api.post('/verify-otp',
            payload);
        dispatch({
            type: VERIFY_OTP_SUCCESS,
            payload: res.data.data
        })

        let msg = res.data.message
        dispatch(setAlert(msg, 'success'))
    } catch (err) {
        const msg = err.response.data.message;
        dispatch({type: VERIFY_OTP_FAILURE})
        dispatch(setAlert(msg, 'danger'))
    }
}

// export function gotoWelcome() {
//     return history.push('/welcome')
// }

//resendOTP
export const resendOTP = (payload) => async (dispatch) => {
    console.log("resendOTP")
    console.log(payload)
    try {
        dispatch({type: RESEND_OTP_REQUEST})
        const res = await api.post('/resend-otp',
            payload);
        console.log(res.data)
        dispatch({
            type: RESEND_OTP_SUCCESS,

        })
        let msg = res.data.message
        dispatch(setAlert(msg, 'success'))

    } catch (err) {
        const msg = err.response.data.message;
        dispatch({type: RESEND_OTP_FAILURE})
        dispatch(setAlert(msg, 'danger'))
    }
}