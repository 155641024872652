import React from 'react'
import { Link } from "react-router-dom";
import left3 from "../../images/left3.png";
import left4 from "../../images/left4.png";
const BodyType2Bg1 = () => {
  return (
    <section className="bodytype_002 bg_01">
        <div className="container ">
          <div className="row align-items-center">
            <div className="col-12 col-lg-3 col-md-3  text-center">
              <div className="img01">
                <img src={left3} alt="left3" className="img-fluid" />
              </div>
            </div>
            <div className="col-12 col-lg-6 col-md-6 text-center">
              <h2>Anterior Pelvic Tilt</h2>
              <h2 className="pain">Low Back Pain</h2>
              <h4>Corrective Program</h4>
              <Link to="#" className="theme_btn1">
                click here
              </Link>
            </div>
            <div className="col-12 col-lg-3 col-md-3 text-center">
              <div className="img01">
                <img src={left4} alt="left4" className="img-fluid" />
              </div>
            </div>
          </div>
        </div>
      </section>
  )
}

export default BodyType2Bg1