import React from 'react'

const ChangeLevel1 = () => {
  return (
    <section className="  change_level_001">
    <div className="container">
        <div className="row">
            <div className="col-12 col-lg-12 col-md-12">
                <h4><span>Current Level:</span> Advanced (Level-3)</h4>
            </div>
        </div>
    </div>
</section>
  )
}

export default ChangeLevel1