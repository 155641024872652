import React from 'react'

const Welcome1 = () => {
  return (
    <section className="welcome_001">
    <div className="container h-100">
        <div className="row h-100">
            <div className="col-12 col-lg-6 col-md-6 m-auto">
                <div className="box_banner_inner text-center">
                    <div>
                        <h1>Anterior Pelvic Tilt</h1>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
  )
}

export default Welcome1