export const SET_ALERT = 'SET_ALERT';
export const REMOVE_ALERT = 'REMOVE_ALERT';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAIL = 'REGISTER_FAIL';
export const USER_LOADED = 'USER_LOADED';
export const AUTH_ERROR = 'AUTH_ERROR';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL'
export const LOGOUT_REQUEST = 'LOGOUT_REQUEST';
export const LOGOUT = 'LOGOUT';
export const LOGOUT_FAILURE = 'LOGOUT_FAILURE';
export const OTP_SUCCESS = 'OTP_SUCCESS'
export const OTP_FAILURE = 'OTP_FAILURE';
export const OTP_REQUEST = 'OTP_REQUEST';
export const VERIFY_OTP_REQUEST = 'VERIFY_OTP_REQUEST';
export const VERIFY_OTP_SUCCESS = 'VERIFY_OTP_SUCCESS'
export const VERIFY_OTP_FAILURE = 'VERIFY_OTP_FAILURE'
export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const REGISTER_REQUEST = 'REGISTER_REQUEST';
export const PASSWORD_RESET_SUCCESS = 'PASSWORD_RESET_SUCCESS';
export const PASSWORD_RESET_REQUEST = 'PASSWORD_RESET_REQUEST';
export const PASSWORD_RESET_FAILURE = 'PASSWORD_RESET_FAILURE';
export const GET_USER_PROGRAM = 'GET_USER_PROGRAM';
export const UPDATE_USER_PROGRAM = 'UPDATE_USER_PROGRAM';
export const USER_PROGRAM_REQUEST = 'USER_PROGRAM_REQUEST';
export const USER_PROGRAM_REQUEST_FAILURE = 'USER_PROGRAM_REQUEST_FAILURE';
export const WEEK_SCHEDULE_REQUEST = 'WEEK_SCHEDULE_REQUEST';
export const WEEK_SCHEDULE_SUCCESS = 'WEEK_SCHEDULE_SUCCESS';
export const WEEK_SCHEDULE_FAILURE = 'WEEK_SCHEDULE_FAILURE';
export const LOGIN_UNVERIFIED = 'LOGIN_UNVERIFIED';
export const RESEND_OTP_REQUEST = 'RESEND_OTP_REQUEST';
export const RESEND_OTP_SUCCESS = 'RESEND_OTP_SUCCESS';
export const RESEND_OTP_FAILURE = 'RESEND_OTP_FAILURE';
export const EXERCISE_DETAIL_REQUEST = 'EXERCISE_DETAIL_REQUEST';
export const EXERCISE_DETAIL_SUCCESS = 'EXERCISE_DETAIL_SUCCESS';
export const EXERCISE_DETAIL_FAILURE = 'EXERCISE_DETAIL_FAILURE';
export const GET_TESTS_REQUEST = 'GET_TESTS_REQUEST';
export const GET_TESTS = 'GET_TESTS';
export const GET_TESTS_FAILURE = 'GET_TESTS_FAILURE';
export const GET_TEST_REQUEST = 'GET_TEST_REQUEST';
export const GET_TEST = 'GET_TEST';
export const GET_TEST_FAILURE = 'GET_TEST_FAILURE';
export const GET_USER_PROGRAM_DAY_REQUEST = 'GET_USER_PROGRAM_DAY_REQUEST';
export const GET_USER_PROGRAM_DAY = 'GET_USER_PROGRAM_DAY';
export const GET_USER_PROGRAM_DAY_FAILURE = 'GET_USER_PROGRAM_DAY_FAILURE';
export const GET_QUESTION_REQUEST = 'GET_QUESTION_REQUEST';
export const GET_QUESTION = 'GET_QUESTION';
export const GET_QUESTION_FAILURE = 'GET_QUESTION_FAILURE';
export const CHECK_PREVIOUS_DAY_REQUEST = 'CHECK_PREVIOUS_DAY_REQUEST';
export const CHECK_PREVIOUS_DAY = 'CHECK_PREVIOUS_DAY';
export const CHECK_PREVIOUS_DAY_FAILURE = 'CHECK_PREVIOUS_DAY_FAILURE';
export const SUBMIT_CHECKIN_FEEDBACK_REQUEST = 'SUBMIT_CHECKIN_FEEDBACK_REQUEST';
export const SUBMIT_CHECKIN_FEEDBACK = 'SUBMIT_CHECKIN_FEEDBACK';
export const SUBMIT_CHECKIN_FEEDBACK_FAILURE = 'SUBMIT_CHECKIN_FEEDBACK_FAILURE';
export const SUBMIT_TEST_REQUEST = 'SUBMIT_TEST_REQUEST';
export const SUBMIT_TEST = 'SUBMIT_TEST';
export const SUBMIT_TEST_FAILURE = 'SUBMIT_TEST_FAILURE';
export const SKIP_TEST_REQUEST = 'SKIP_TEST_REQUEST';
export const SKIP_TEST = 'SKIP_TEST';
export const SKIP_TEST_FAILURE = 'SKIP_TEST_FAILURE';
export const RECOMMENDED_PRODUCTS_REQUEST = 'RECOMMENDED_PRODUCTS_REQUEST';
export const RECOMMENDED_PRODUCTS_SUCCESS = 'RECOMMENDED_PRODUCTS_SUCCESS';
export const RECOMMENDED_PRODUCTS_FAILURE = 'RECOMMENDED_PRODUCTS_FAILURE';
export const TESTIMONIALS_REQUEST = 'TESTIMONIALS_REQUEST';
export const TESTIMONIALS_SUCCESS = 'TESTIMONIALS_SUCCESS';
export const TESTIMONIALS_FAILURE = 'TESTIMONIALS_FAILURE';
export const EDUCATIONAL_VIDEOS_REQUEST = 'EDUCATIONAL_VIDEOS_REQUEST';
export const EDUCATIONAL_VIDEOS_SUCCESS = 'EDUCATIONAL_VIDEOS_SUCCESS';
export const EDUCATIONAL_VIDEOS_FAILURE = 'EDUCATIONAL_VIDEOS_FAILURE';