import {
    EXERCISE_DETAIL_FAILURE,
    EXERCISE_DETAIL_REQUEST,
    EXERCISE_DETAIL_SUCCESS,
    GET_USER_PROGRAM,
    USER_PROGRAM_REQUEST,
    USER_PROGRAM_REQUEST_FAILURE,
    WEEK_SCHEDULE_FAILURE,
    WEEK_SCHEDULE_REQUEST,
    WEEK_SCHEDULE_SUCCESS,
    GET_TESTS_REQUEST,
    GET_TESTS,
    GET_TESTS_FAILURE,
    GET_TEST_FAILURE,
    GET_TEST,
    GET_TEST_REQUEST,
    GET_USER_PROGRAM_DAY_FAILURE,
    GET_USER_PROGRAM_DAY,
    GET_USER_PROGRAM_DAY_REQUEST,
    GET_QUESTION_FAILURE,
    GET_QUESTION,
    GET_QUESTION_REQUEST,
    CHECK_PREVIOUS_DAY_FAILURE,
    CHECK_PREVIOUS_DAY,
    CHECK_PREVIOUS_DAY_REQUEST,
    SUBMIT_CHECKIN_FEEDBACK_REQUEST,
    SUBMIT_CHECKIN_FEEDBACK,
    SUBMIT_CHECKIN_FEEDBACK_FAILURE,
    PRODUCTS,
    PRODUCTS_SUCCESS,
    RECOMMENDED_PRODUCTS_SUCCESS,
    RECOMMENDED_PRODUCTS_REQUEST,
    TESTIMONIALS_REQUEST,
    TESTIMONIALS_SUCCESS,
    EDUCATIONAL_VIDEOS_SUCCESS,
    EDUCATIONAL_VIDEOS_REQUEST
} from "./types";
import api from '../utils/api';
import {setAlert} from "./alert";

export const getProgram = () => async (dispatch) => {
    try {
        dispatch({
            type: USER_PROGRAM_REQUEST
        })
        let token = localStorage.getItem('token')
        // console.log(token)
        const res = await api.get('/user-current-program', {
            headers: {
                Authorization: 'Bearer ' + token
            }
        });

        dispatch({
            type: GET_USER_PROGRAM,
            payload: res.data.data
        });
    } catch (err) {
        const msg = err && err.response ? err.response.data.message : null;
        if (msg) {
            dispatch(setAlert(msg, 'danger'))
        }
        dispatch({
            type: USER_PROGRAM_REQUEST_FAILURE
        })
    }
};

export const getWeekSchedule = (id) => async (dispatch) => {
    try {
        dispatch({
            type: WEEK_SCHEDULE_REQUEST
        })
        const token = localStorage.getItem('token')
        // const program = JSON.parse(localStorage.getItem('program'))
        // const programId = program.id

        const res = await api.post("/user-week-schedule", {"program_id": id}, {
            headers: {
                Authorization: 'Bearer ' + token
            }
        })
        console.log(res.data.data, "response")
        dispatch({
            type: WEEK_SCHEDULE_SUCCESS,
            payload: res.data.data
        })
    } catch (err) {
        const msg = err.response.data.message;
        dispatch({type: WEEK_SCHEDULE_FAILURE})
        dispatch(setAlert(msg, 'danger'))

    }

}

export const getExerciseDetail = (day_id) => async (dispatch) => {

    try {
        dispatch({type: EXERCISE_DETAIL_REQUEST})
        const token = localStorage.getItem('token')
        const res = await api.get(`user-day-exercises/${day_id}`, {
            headers: {

                Authorization: 'Bearer ' + token
            }
        })
        console.log(res)
        dispatch({
            type: EXERCISE_DETAIL_SUCCESS,
            payload: res.data.data
        })
    } catch (err) {
        const msg = err.response.data.message;
        dispatch({type: EXERCISE_DETAIL_FAILURE})
        dispatch(setAlert(msg, 'danger'))
    }

}

export const getProgramTests = (program_id) => async (dispatch) => {
    try {
        dispatch({type: GET_TESTS_REQUEST})
        const token = localStorage.getItem('token')
        const res = await api.get(`program-test/${program_id}`, {
            headers: {

                Authorization: 'Bearer ' + token
            }
        })
        dispatch({
            type: GET_TESTS,
            payload: res.data.data
        })
    } catch (err) {
        const msg = err.response.data.message;
        dispatch({type: GET_TESTS_FAILURE})
        dispatch(setAlert(msg, 'danger'))
    }
}
export const getProgramTest = (test_id) => async (dispatch) => {
    try {
        dispatch({type: GET_TEST_REQUEST})
        const token = localStorage.getItem('token')
        const res = await api.get(`program-tests/${test_id}`, {
            headers: {

                Authorization: 'Bearer ' + token
            }
        })
        dispatch({
            type: GET_TEST,
            payload: res.data.data
        })
    } catch (err) {
        const msg = err.response.data.message;
        dispatch({type: GET_TEST_FAILURE})
        dispatch(setAlert(msg, 'danger'))
    }
}
export const getUserProgramDay = (day_id) => async (dispatch) => {
    try {
        dispatch({type: GET_USER_PROGRAM_DAY_REQUEST})
        const token = localStorage.getItem('token')
        const res = await api.get(`user-program-days/${day_id}`, {
            headers: {

                Authorization: 'Bearer ' + token
            }
        })
        dispatch({
            type: GET_USER_PROGRAM_DAY,
            payload: res.data.data
        })
    } catch (err) {
        const msg = err.response.data.message;
        dispatch({type: GET_USER_PROGRAM_DAY_FAILURE})
        dispatch(setAlert(msg, 'danger'))
    }
}
export const getQuestion = (question_id) => async (dispatch) => {
    try {
        dispatch({type: GET_QUESTION_REQUEST})
        const token = localStorage.getItem('token')
        const res = await api.get(`questions/${question_id}`, {
            headers: {

                Authorization: 'Bearer ' + token
            }
        })
        dispatch({
            type: GET_QUESTION,
            payload: res.data.data
        })
    } catch (err) {
        const msg = err.response.data.message;
        dispatch({type: GET_QUESTION_FAILURE})
        dispatch(setAlert(msg, 'danger'))
    }
}
export const checkPreviousDay = (current_day_id) => async (dispatch) => {
    try {
        dispatch({type: CHECK_PREVIOUS_DAY_REQUEST})
        const token = localStorage.getItem('token')
        const res = await api.post("check-user-previous-day-exercises", {
            user_day_id: current_day_id
        }, {
            headers: {

                Authorization: 'Bearer ' + token
            }
        })
        dispatch({
            type: CHECK_PREVIOUS_DAY,
            payload: res.data.data
        })
    } catch (err) {
        const msg = err.response.data.message;
        dispatch({type: CHECK_PREVIOUS_DAY_FAILURE})
        dispatch(setAlert(msg, 'danger'))
    }
}
export const submitPreviousDayFeedback = (payload) => async (dispatch) => {
    try {
        dispatch({type: SUBMIT_CHECKIN_FEEDBACK_REQUEST})
        const token = localStorage.getItem('token')
        const res = await api.post("user-program-day-feedback", payload, {
            headers: {

                Authorization: 'Bearer ' + token
            }
        })
        dispatch({
            type: SUBMIT_CHECKIN_FEEDBACK,
            payload: res.data.data
        })
    } catch (err) {
        const msg = err.response.data.message;
        dispatch({type: SUBMIT_CHECKIN_FEEDBACK_FAILURE})
        dispatch(setAlert(msg, 'danger'))
    }
}

export const getRecommendedProducts = () => async dispatch => {
    try {
        dispatch({type: RECOMMENDED_PRODUCTS_REQUEST});
        const token = localStorage.getItem('token')
        const res = await api.get("/equipment-list",{ headers: {

                Authorization: 'Bearer ' + token
            }});
        dispatch({
            type: RECOMMENDED_PRODUCTS_SUCCESS,
            payload: res.data.data
        });
    } catch (error) {
        const msg = error.response.data.message;
    }
};
export const getTestimonials = () => async dispatch => {
    try {
        dispatch({type: TESTIMONIALS_REQUEST});
        const token = localStorage.getItem('token')
        const res = await api.get("/testimonials",{ headers: {

                Authorization: 'Bearer ' + token
            }});
        dispatch({
            type: TESTIMONIALS_SUCCESS,
            payload: res.data.data
        });
    } catch (error) {
        const msg = error.response.data.message;
    }
};

export const getEducationalVideos = () => async dispatch => {
    try {
        dispatch({type: EDUCATIONAL_VIDEOS_REQUEST});
        const token = localStorage.getItem('token')
        const res = await api.get("/education-videos"+'?type=APT',{ headers: {

                Authorization: 'Bearer ' + token
            },
        });
        dispatch({
            type: EDUCATIONAL_VIDEOS_SUCCESS,
            payload: res.data.data
        });
    } catch (error) {
        const msg = error.response.data.message;
    }
};