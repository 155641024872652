import {
    CHECK_PREVIOUS_DAY,
    CHECK_PREVIOUS_DAY_FAILURE,
    CHECK_PREVIOUS_DAY_REQUEST,
    EXERCISE_DETAIL_FAILURE,
    EXERCISE_DETAIL_REQUEST,
    EXERCISE_DETAIL_SUCCESS,
    GET_QUESTION,
    GET_QUESTION_FAILURE,
    GET_QUESTION_REQUEST,
    GET_TEST,
    GET_TEST_FAILURE,
    GET_TEST_REQUEST,
    GET_TESTS,
    GET_TESTS_FAILURE,
    GET_TESTS_REQUEST,
    GET_USER_PROGRAM,
    GET_USER_PROGRAM_DAY,
    GET_USER_PROGRAM_DAY_FAILURE,
    GET_USER_PROGRAM_DAY_REQUEST,
    SUBMIT_CHECKIN_FEEDBACK,
    SUBMIT_CHECKIN_FEEDBACK_FAILURE,
    SUBMIT_CHECKIN_FEEDBACK_REQUEST,
    USER_PROGRAM_REQUEST,
    USER_PROGRAM_REQUEST_FAILURE,
    WEEK_SCHEDULE_FAILURE,
    WEEK_SCHEDULE_REQUEST,
    WEEK_SCHEDULE_SUCCESS,
    RECOMMENDED_PRODUCTS_SUCCESS,
    RECOMMENDED_PRODUCTS_REQUEST,
    RECOMMENDED_PRODUCTS_FAILURE,
    TESTIMONIALS_SUCCESS,
    TESTIMONIALS_REQUEST,
    TESTIMONIALS_FAILURE,
    EDUCATIONAL_VIDEOS_REQUEST, EDUCATIONAL_VIDEOS_SUCCESS, EDUCATIONAL_VIDEOS_FAILURE,
} from '../actions/types';

const initialState = {
    program: null,
    loading: false,
    weekSchedule: null,
    programId: null,
    exerciseDetail: null,
    exercises: null,
    submitCheckInSuccess: false
};

function programReducer(state = initialState, action) {
    const {type, payload} = action;

    switch (type) {
        case USER_PROGRAM_REQUEST:
        case WEEK_SCHEDULE_REQUEST:
            return {
                ...state,
                weekScheduleRequest: true,
                weekScheduleSuccess: false,
                weekScheduleFailure: false
            }
        case GET_USER_PROGRAM:
            localStorage.setItem('program', JSON.stringify(payload.program));
            return {
                ...state,
                program: payload.program,
                programId: payload.program_id,
                loading: false
            };
        case USER_PROGRAM_REQUEST_FAILURE:
            return {
                ...state,
                loading: false
            };
        case WEEK_SCHEDULE_SUCCESS:
            return {
                ...state,
                weekSchedule: payload,
                weekScheduleRequest: false,
                weekScheduleSuccess: true,
                weekScheduleFailure: false
            }
        case WEEK_SCHEDULE_FAILURE:
            return {
                ...state,
                weekScheduleRequest: false,
                weekScheduleSuccess: false,
                weekScheduleFailure: true
            }
        case EXERCISE_DETAIL_REQUEST:
            return {
                ...state,
                exerciseDetailRequest: true,
                exerciseDetailSuccess: false,
                exerciseDetailFailure: false,
                exercises: null
            }
        case EXERCISE_DETAIL_SUCCESS:
            return {
                ...state,
                exerciseDetailRequest: false,
                exerciseDetailSuccess: true,
                exerciseDetailFailure: false,
                exercises: payload
            }
        case EXERCISE_DETAIL_FAILURE:
            return {
                ...state,
                exerciseDetailRequest: false,
                exerciseDetailSuccess: false,
                exerciseDetailFailure: true
            }
        case GET_TESTS_REQUEST:
            return {
                ...state,
                getTestsRequest: true,
                getTestsSuccess: false,
                getTestsFailure: false,
                tests: null
            }
        case GET_TESTS:
            return {
                ...state,
                getTestsRequest: false,
                getTestsSuccess: true,
                getTestsFailure: false,
                program_tests: payload
            }
        case GET_TESTS_FAILURE:
            return {
                ...state,
                getTestsRequest: false,
                getTestsSuccess: false,
                getTestsFailure: true
            }
        case GET_TEST_REQUEST:
            return {
                ...state,
                getTestRequest: true,
                getTestSuccess: false,
                getTestFailure: false,
                test_details: null
            }
        case GET_TEST:
            return {
                ...state,
                getTestRequest: false,
                getTestSuccess: true,
                getTestFailure: false,
                test_details: payload
            }
        case GET_TEST_FAILURE:
            return {
                ...state,
                getTestRequest: false,
                getTestSuccess: false,
                getTestFailure: true
            }
        case GET_USER_PROGRAM_DAY_REQUEST:
            return {
                ...state,
                getTestRequest: true,
                getTestSuccess: false,
                getTestFailure: false,
                user_program_day: null
            }
        case GET_USER_PROGRAM_DAY:
            return {
                ...state,
                getTestRequest: false,
                getTestSuccess: true,
                getTestFailure: false,
                user_program_day: payload
            }
        case GET_USER_PROGRAM_DAY_FAILURE:
            return {
                ...state,
                getTestRequest: false,
                getTestSuccess: false,
                getTestFailure: true
            }
        case GET_QUESTION_REQUEST:
            return {
                ...state,
                getQuestionRequest: true,
                getQuestionSuccess: false,
                getQuestionFailure: false,
                question_details: null
            }
        case GET_QUESTION:
            return {
                ...state,
                getQuestionRequest: false,
                getQuestionSuccess: true,
                getQuestionFailure: false,
                question_details: payload
            }
        case GET_QUESTION_FAILURE:
            return {
                ...state,
                getQuestionRequest: false,
                getQuestionSuccess: false,
                getQuestionFailure: true
            }
        case CHECK_PREVIOUS_DAY_REQUEST:
            return {
                ...state,
                checkPreviousDayRequest: true,
                checkPreviousDaySuccess: false,
                checkPreviousDayFailure: false,
                previous_day: null
            }
        case CHECK_PREVIOUS_DAY:
            return {
                ...state,
                checkPreviousDayRequest: false,
                checkPreviousDaySuccess: true,
                checkPreviousDayFailure: false,
                previous_day: payload
            }
        case CHECK_PREVIOUS_DAY_FAILURE:
            return {
                ...state,
                checkPreviousDayRequest: false,
                checkPreviousDaySuccess: false,
                checkPreviousDayFailure: true
            }
        case SUBMIT_CHECKIN_FEEDBACK_REQUEST:
            return {
                ...state,
                submitCheckInRequest: true,
                submitCheckInSuccess: false,
                submitCheckInFailure: false
            }
        case SUBMIT_CHECKIN_FEEDBACK:
            return {
                ...state,
                submitCheckInRequest: false,
                submitCheckInSuccess: true,
                submitCheckInFailure: false
            }
        case SUBMIT_CHECKIN_FEEDBACK_FAILURE:
            return {
                ...state,
                submitCheckInRequest: false,
                submitCheckInSuccess: false,
                submitCheckInFailure: true
            }

        case RECOMMENDED_PRODUCTS_REQUEST:
            return {
                ...state,
                RecommendedProductsRequest: true,
                RecommendedProductsSuccess: false,
                RecommendedProductsFailure: false,
                products:null
            }
        case RECOMMENDED_PRODUCTS_SUCCESS:
            return {
                ...state,
                 RecommendedProductsRequest: false,
                RecommendedProductsSuccess: true,
                RecommendedProductsFailure: false,
                products:payload
            }
        case RECOMMENDED_PRODUCTS_FAILURE:
            return {
                ...state,
                RecommendedProductsRequest: false,
                RecommendedProductsSuccess: false,
                RecommendedProductsFailure: true
            }
            case TESTIMONIALS_REQUEST:
            return {
                ...state,
                TestimonialsRequest: true,
                TestimonialsSuccess: false,
                TestimonialsFailure: false,
                testimonial:null
            }
        case TESTIMONIALS_SUCCESS:
            return {
                ...state,
                 TestimonialsRequest: false,
                TestimonialsSuccess: true,
                TestimonialsFailure: false,
                testimonial:payload
            }
        case TESTIMONIALS_FAILURE:
            return {
                ...state,
                TestimonialsRequest: false,
                TestimonialsSuccess: false,
                TestimonialsFailure: true
            }
        case EDUCATIONAL_VIDEOS_REQUEST:
            return {
                ...state,
                EducationVideosRequest: true,
                EducationVideosSuccess: false,
                EducationVideosFailure: false,
                educational_video:null
            }
        case EDUCATIONAL_VIDEOS_SUCCESS:
            return {
                ...state,
                EducationVideosRequest: false,
                EducationVideosSuccess: true,
                EducationVideosFailure: false,
                educational_video:payload
            }
        case EDUCATIONAL_VIDEOS_FAILURE:
            return {
                ...state,
                EducationVideosRequest: false,
                EducationVideosSuccess: false,
                EducationVideosFailure: true
            }
        default:
            return state;
    }
}

export default programReducer;
