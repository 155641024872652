import React, {useEffect} from 'react'
// import { Link } from 'react-router-dom'
import StatusCheckinModal from './StatusCheckinModal'
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {getProgram} from "../../../actions/user_program";
import {FadeLoader} from "react-spinners";

const Banner = ({program, getProgram, weekSchedule}) => {
    useEffect(() => {
        if (!program) {
            getProgram()
        }
    }, [])
    if (!program) {
        return (
            <section className="welcome_001">
                <div className="container h-100">
                    <div className="row h-100">
                        <div className="col-12 col-lg-6 col-md-6 m-auto">
                            <div className="box_banner_inner text-center">
                                <FadeLoader color={"#FB1417"} height={10}/>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
    return (
        <>
            <section className="welcome_001">
                <div className="container h-100">
                    <div className="row h-100">
                        <div className="col-12 col-lg-6 col-md-6 m-auto">
                            <div className="box_banner_inner text-center">
                                <div>
                                    <h1> {program.name} </h1>
                                    <h5> {program.description}</h5>
                                    <p>{program.duration}</p>
                                    <StatusCheckinModal weekSchedule={weekSchedule} program={program}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

Banner.propTypes = {
    program: PropTypes.object.isRequired,
    weekSchedule: PropTypes.array
};

const mapStateToProps = state => ({
    program: state.programReducer.program,
    weekSchedule: state.programReducer.weekSchedule
});

export default connect(mapStateToProps, {getProgram})(Banner);