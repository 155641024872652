import React from "react";
import Modal from "react-bootstrap/Modal";
import {useState} from "react";
import {Link} from "react-router-dom";

const TestCompleteModal = ({handleClose, show}) => {
    return (

        <Modal show={show} onHide={handleClose} className="modal_test" centered>
            <Modal.Body className="modal-body">
                <h4>
                    Thanking for taking the test.
                    <br/>
                    Now you will start your exercises
                </h4>
                <Link to="/assessment-timer1" className="theme_btn1 small_btn">
                    start exercises
                </Link>
            </Modal.Body>
        </Modal>
    );
};

export default TestCompleteModal;
