import React from "react";

import Header from "../../Layout/Header";
import Footer from "../../Layout/Footer";
import Welcome1 from "./Welcome1";
import Assessment1Day1 from "./Assessment1Day1";
import Welcome2 from "./Welcome2";
import Assessment2Day1 from "./Assessment2Day1";
const Weekly = () => {
  return (
    <div>
      <Header />
      <Welcome1 />
      <Assessment1Day1 />
      <Welcome2 />
      <Assessment2Day1 />
      <Footer />
    </div>
  );
};

export default Weekly;
