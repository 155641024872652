import React from 'react'
import {Link} from 'react-router-dom'

const BreadcrumbMenu = () => {
    return (
        <>
            <section className="welcome_002">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-12 col-lg-8 col-md-8">
                            <ul className="breadcrumb main">
                                <li className=""><Link to="/index">Home </Link></li>
                                <li><Link to="/welcome"> Welcome</Link></li>
                                <li className="active"><Link to="#"> Assestment</Link></li>
                            </ul>
                        </div>
                        <div className="col-12 col-lg-4 col-md-4">
                            <div className="nav_week justify-content-end">
                                <Link to="#" className="daily">Grid View</Link>
                                <div className="button-switch">
                                    <input type="checkbox" id="addsetting"
                                           className="switch sw_btn"/>
                                    <label htmlFor="addsetting" className="lbl-off"> </label>
                                </div>
                                <Link to="#" className="weekly">List View</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default BreadcrumbMenu
