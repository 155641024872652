import React, {useEffect} from "react"
import ScrollTopOnMount from "../../Layout/ScrollTopOnMount";
import Header from "../../Layout/Header";
import Footer from "../../Layout/Footer";
import {Container, Row, Col} from "react-bootstrap";
import imgProduct from "../../images/calf-stretcher.jpg"
import imgProduct2 from "../../images/round-roller-foam.jpg"
import imgProduct3 from "../../images/roller-stick.jpg"
import {Link, NavLink} from "react-router-dom"
import AmazonImg from "../../images/amazon-icon.png"
import EbayImg from "../../images/ebay-icon.png"
import {getRecommendedProducts} from "../../../actions/user_program";
import {connect} from "react-redux";
import {AiOutlineTwitter} from "react-icons/ai"
import {FaFacebookF} from "react-icons/fa"
import {FaTiktok} from "react-icons/fa"
import profile from "../../images/Group 29358.png"
const RecommendedProducts = ({getRecommendedProducts,products}) => {

    useEffect(() => {
        getRecommendedProducts()

    }, [])

    const ListProducts = [{
            imgUrl: imgProduct,
            title: "CALFPRO Deep Calf Stretcher - Slant Board Stretching for Plantar Fasciitis & Achilles Tendonitis, Feet Mobility & Heel Pain Relief, Incline Wedge for Strained Ankle - Foot Rocker Stretch Replacement",
            description: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime mollitia molestiae quas vel sint commodi repudiandae consequuntur voluptatum laborum numquam blanditiis harum quisquam eius sed odit fugiat iusto fuga praesentium optio, eaque rerum! Provident similique accusantium nemo autem.",
            productUrl: "https://www.amazon.com/CALFPRO-Deep-Calf-Stretcher-Replacement/dp/B09VCMRKBB",
            productVendor: "amazon"
        },
        {
            imgUrl: imgProduct2,
            title: "CALFPRO Deep Calf Stretcher - Slant Board Stretching for Plantar Fasciitis & Achilles Tendonitis, Feet Mobility & Heel Pain Relief, Incline Wedge for Strained Ankle - Foot Rocker Stretch Replacement",
            description: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime mollitia molestiae quas vel sint commodi repudiandae consequuntur voluptatum laborum numquam blanditiis harum quisquam eius sed odit fugiat iusto fuga praesentium optio, eaque rerum! Provident similique accusantium nemo autem.",
            productUrl: "https://www.amazon.com/CALFPRO-Deep-Calf-Stretcher-Replacement/dp/B09VCMRKBB",
            productVendor: "ebay"
        },
        {
            imgUrl: imgProduct3,
            title: "CALFPRO Deep Calf Stretcher - Slant Board Stretching for Plantar Fasciitis & Achilles Tendonitis, Feet Mobility & Heel Pain Relief, Incline Wedge for Strained Ankle - Foot Rocker Stretch Replacement",
            description: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime mollitia molestiae quas vel sint commodi repudiandae consequuntur voluptatum laborum numquam blanditiis harum quisquam eius sed odit fugiat iusto fuga praesentium optio, eaque rerum! Provident similique accusantium nemo autem.",
            productUrl: "https://www.amazon.com/CALFPRO-Deep-Calf-Stretcher-Replacement/dp/B09VCMRKBB",
            productVendor: "amazon"
        },
        {
            imgUrl: imgProduct,
            title: "CALFPRO Deep Calf Stretcher - Slant Board Stretching for Plantar Fasciitis & Achilles Tendonitis, Feet Mobility & Heel Pain Relief, Incline Wedge for Strained Ankle - Foot Rocker Stretch Replacement",
            description: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime mollitia molestiae quas vel sint commodi repudiandae consequuntur voluptatum laborum numquam blanditiis harum quisquam eius sed odit fugiat iusto fuga praesentium optio, eaque rerum! Provident similique accusantium nemo autem.",
            productUrl: "https://www.amazon.com/CALFPRO-Deep-Calf-Stretcher-Replacement/dp/B09VCMRKBB",
            productVendor: "amazon"
        },
        {
            imgUrl: imgProduct2,
            title: "CALFPRO Deep Calf Stretcher - Slant Board Stretching for Plantar Fasciitis & Achilles Tendonitis, Feet Mobility & Heel Pain Relief, Incline Wedge for Strained Ankle - Foot Rocker Stretch Replacement",
            description: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime mollitia molestiae quas vel sint commodi repudiandae consequuntur voluptatum laborum numquam blanditiis harum quisquam eius sed odit fugiat iusto fuga praesentium optio, eaque rerum! Provident similique accusantium nemo autem.",
            productUrl: "https://www.amazon.com/CALFPRO-Deep-Calf-Stretcher-Replacement/dp/B09VCMRKBB",
            productVendor: "ebay"
        },
        {
            imgUrl: imgProduct3,
            title: "CALFPRO Deep Calf Stretcher - Slant Board Stretching for Plantar Fasciitis & Achilles Tendonitis, Feet Mobility & Heel Pain Relief, Incline Wedge for Strained Ankle - Foot Rocker Stretch Replacement",
            description: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime mollitia molestiae quas vel sint commodi repudiandae consequuntur voluptatum laborum numquam blanditiis harum quisquam eius sed odit fugiat iusto fuga praesentium optio, eaque rerum! Provident similique accusantium nemo autem.",
            productUrl: "https://www.amazon.com/CALFPRO-Deep-Calf-Stretcher-Replacement/dp/B09VCMRKBB",
            productVendor: "amazon"
        }];

    const ProductCard = (data) => {
            const productData = data?.data;

        return(
            <div className={"product-box"}>

                <div className={"mx-auto text-center"}>
                    <img className={"img-fluid product-img"} src={productData?.image} alt={productData?.title} title={data?.title}/>
                </div>
                <div className={"mt-3 p-2"}>
                    <div className={"product-info d-flex flex-column text-center"}>
                        <h3>
                            <b>{productData?.title}</b>
                        </h3>
                        <p>
                            <span>
                                {productData?.description}
                            </span>
                        </p>
                    </div>
                    <div className="clearfix mt-3"></div>
                    <div className={"mx-auto text-center"}>
                        <span>Buy it On:</span>

                                <a href={productData?.referral_link} target="_blank" className={"btn-full"}>
                                    <img className={"img-fluid"} src={AmazonImg} alt={"Amazon link"} title={"Amazon link"} />
                                </a>

                    </div>
                </div>
            </div>
        )
    }

    return(
        <div>
            <ScrollTopOnMount />
            <Header />
            <div className={"banner-recomended"}>
                <Container className={"h-100"}>
                    <Row className={"h-100"}>
                        <Col className={"col-12 col-lg-6 col-md-6 m-auto"}>
                            {/*<div className="box_banner_inner text-center">*/}
                            {/*    <h3>Recommended Products</h3>*/}
                            {/*</div>*/}
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className={"clearfix"}></div>

            <section className={"recomended-products"}>
                <Container>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={6} xl={6} className={"mx-auto"}>
                            <div className={"mx-auto text-center page-title"}>
                                <h2>
                                    <b>Product Recommendations</b>
                                </h2>
                                <p>
                                    Our favorite products used in the Body Solutions Programs
                                </p>
                            </div>

                        </Col>
                    </Row>

                    <div className={"clearfix mt-5"}></div>
                    <Row>

                        {
                            products && products.map((products, key)=>{
                                if(products){
                                    return(
                                        <Col xs={12} sm={6} md={4} lg={3} xl={3} key={key}>
                                            <ProductCard data={products}/>
                                        </Col>
                                    )

                                }

                            })
                        }

                    </Row>
                    <Row className={"mt-5"}>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12} className={"mx-auto"}>
                            <div className="social-links">
                                <div className="kit">
                                    <h3>Share this kit:</h3>
                                    <ul className="links-icons">
                                        <li><a href=""><AiOutlineTwitter/></a></li>
                                        <li><a href=""><FaFacebookF/></a></li>
                                        <li><a href=""><FaTiktok/></a></li>
                                    </ul>
                                </div>
                                <div className="social-profile">
                                    <div className="wrapper">
                                        <img src={profile} alt="profile" className="img-fluid"/>
                                    </div>
                                    <div className="content">
                                        <h3>@bodysolutions</h3>
                                        <a href="" className="btn-follow">Follow</a>
                                    </div>
                                </div>
                            </div>

                        </Col>
                    </Row>
                </Container>
            </section>
            <Footer />
        </div>
    )
}
const mapStateToProps = state => {

    return {
        products: state.programReducer.products
    }
}
export default connect(mapStateToProps, {getRecommendedProducts}) (RecommendedProducts)