import React, {useEffect, useState} from "react";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import {Provider} from 'react-redux';
import Alert from "./Layout/Alert"
import Login from "./Auth/Login";
import SignUp from "./Auth/Signup";
import ForgotPassword from "./Auth/ForgotPassword";
import BodyType from "./Pages/BodyType";
import ChangeLevel from "./Pages/ChangeLevel";
import Home from "./Pages/Home/";
import Weekly from "./Pages/WeeklyPrograms";
import Welcome from "./Pages/WelcomePage";
import Layout from "./Layout/Layout";
import AssessmentDay1 from "./Pages/AssessmentDay1";
import AssessmentGridview from "./Pages/AssessmentGridView";
import AssessmentTimer1 from "./Pages/AssessmentTimer";
import AssessmentTimer2 from "./Pages/AssessmentTimer2";
import AssessmentTimer3 from "./Pages/AssessmentTimer3";
import AssessmentTimer4 from "./Pages/AssessmentTimer4";
import Test1 from "./Pages/AssessmentTests";
import Test2 from "./Pages/Test2";
import Test3 from "./Pages/Test3";
import Test4 from "./Pages/Test4";
import Tests from "./Pages/Tests";
import store from "../store";
import setAuthToken from "../utils/setAuthToken"
import VerifyEmail from "./Auth/VerifyEmail";
import ResetPassword from "./Auth/ResetPassword";
import history from '../history';
import PrivateRoute from "./routing/PrivateRoute";
import {loadUser} from "../actions/auth";
import ProtectedRoute from "./routing/ProtectedRoute";
import TestVideos from "./Pages/Tests/TestVideos";

import TestimonialsDetail from "./Pages/Testimonials/TestimonialsDetail";
import RecommendedProducts from "./Pages/Recommended/RecommendedProducts";
import Education from "./Pages/Education/Education";


function App() {
    // if (localStorage.token) {
    //     // if there is a token set axios headers for all requests
    //     setAuthToken(localStorage.token);
    // }
    // const [ user, setUser ] = useState(loggedInUser)
    // const handleLogin = () => setUser(loggedInUser);
    // const handleLogout = () => setUser(null);
    // useEffect(() => {
    //     // check for token in LS when app first runs
    //     // try to fetch a user, if no token or invalid token we
    //     // will get a 401 response from our API
    //
    //     store.dispatch(loadUser());
    //
    //     // log user out from all tabs if they log out in one tab
    //     window.addEventListener('storage', () => {
    //         if (!localStorage.token) store.dispatch({ type: LOGOUT });
    //     });
    // }, []);
    return (
        <div className="App">
            <Provider store={store}>
                <BrowserRouter history={history}>
                    <Alert/>
                    <Routes>
                        <Route exact path="/login" element={<Login/>}/>
                        <Route exact path="/signup" element={<SignUp/>}/>
                        <Route exact path="/verify-email" element={<VerifyEmail/>}/>
                        <Route exact path="/forgot-password" element={<ForgotPassword/>}/>
                        <Route exact path="/reset-password" element={<ResetPassword/>}/>
                        <Route element={<ProtectedRoute/>}>
                            <Route exact path="/" element={<Home/>}/>
                            <Route exact path="/welcome" element={<Welcome/>}/>
                            <Route exact path="/weekly" element={<Weekly/>}/>
                            <Route exact path="/body-type" element={<BodyType/>}/>
                            <Route exact path="/change-level" element={<ChangeLevel/>}/>
                            <Route exact path="/assessment-day1" element={<AssessmentDay1/>}/>
                            <Route exact path="/program/:id" element={<AssessmentGridview/>}/>
                            <Route exact path="/assessment-timer1" element={<AssessmentTimer1/>}/>
                            <Route exact path="/assessment-timer2" element={<AssessmentTimer2/>}/>
                            <Route exact path="/assessment-timer3" element={<AssessmentTimer3/>}/>
                            <Route exact path="/assessment-timer4" element={<AssessmentTimer4/>}/>
                            <Route exact path="/assessment-test/:id" element={<Test1/>}/>
                            <Route exact path="/assessment-test2" element={<Test2/>}/>
                            <Route exact path="/assessment-test3" element={<Test3/>}/>
                            <Route exact path="/assessment-test4" element={<Test4/>}/>
                            <Route exact path="/test-instructions/:id" element={<Tests/>}/>
                            <Route exact path="/tests/:id" element={<TestVideos/>}/>
                            <Route exact path="/recommended-products" element={<RecommendedProducts/>}/>
                            <Route exact path="/testimonials" element={<TestimonialsDetail/>}/>
                            <Route exact path="/education" element={<Education/>}/>
                        </Route>
                    </Routes>
                </BrowserRouter>
            </Provider>

        </div>
    );
}

export default App;
