import React, { Navigate } from "react";
import Footer from "../../Layout/Footer";
import Header from "./Header";
import Home1 from "./Home1";
import Home2 from "./Home2";
import Home3 from "./Home3";
import Home4 from "./Home4";
import Home5 from "./Home5";
import Home6 from "./Home6";
import Home7 from "./Home7";
import Home8 from "./Home8";
import Home9 from "./Home9";
import Home10 from "./Home10";
import Home11 from "./Home11";
import Home12 from "./Home12";
import Home13 from "./Home13";

 const Home = () => {

  return (
    <>
      <Header />
      <Home1 />
      <Home2 />
      <Home3 />
      <Home4/>
      <Home5 />
      <Home6 />
      <Home7 />
      <Home8 />
      <Home9 />
      <Home10 />
      <Home11 />
      <Home12 />
      <Home13 />
      <Footer />
    </>
  );
};

export default Home
