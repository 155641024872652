import React from 'react'
import level1 from "../../images/level1.png"
import level2 from "../../images/level2.png"

const ChangeLevel2 = () => {
  return (
    <section className="change_level_002">
        <div className="container">
            <div className="row">
                <div className="col-12 col-lg-12 col-md-12 level01">
                    <h4>Want to select a different level?</h4>
                    <p>If you select an exercise, it will replace the original exercise in your program. </p>
                    <p>You can change the exercise levels at any time.</p>
                </div>
                <div className="col-12 col-md-6 col-lg-6">
                    <ul className="checkbox_list pd-list">
                        <li>
                            <label className="container-checkbox" id="ex1">
                                <input id="firstbox" type="checkbox"  value="1" name="fooby[]"/>
                          <span className=" checkmark main_check">
                             <div className="row">
                                <div className="col-12 col-lg-6 col-md-6">
                                   <div className="box_levelimg">
<video width="100%" controls="">
  <source src="videos/video-1649785711.mp4" type="video/mp4"/>
  Your browser does not support HTML video.
</video>
                                   </div>
                                </div>
                                <div className="col-12 col-lg-6 col-md-6">
                                   <h6>oblique Leg Lift: <span className="level">Level 1</span></h6>
                                   <p>This is dummy copy. It is not meant to be read. It has been placed here solely to demonstrate the look and feel of finished, typeset text.</p>
                                   <p> <span className="ten">Reps:</span> 12</p>
                                    <p> <span className="ten">Time: </span> 5 min</p>
                                </div>
                             </div>
                          </span>
                            </label>
                        </li>
                    </ul>
                </div>
                <div className="col-12 col-md-6 col-lg-6">
                    <ul className="checkbox_list pd-list">
                        <li>
                            <label className="container-checkbox" id="ex2">
                                <input id="secondbox" type="checkbox" value="2" name="fooby[]"/>
                          <span className=" checkmark main_check">
                             <div className="row">
                                <div className="col-12 col-lg-6 col-md-6">
                                   <div className="box_levelimg">
                                     <video width="100%" controls="">
  <source src="videos/video-1649785676.mp4" type="video/mp4"/>
  Your browser does not support HTML video.
</video>
                                   </div>
                                </div>
                                <div className="col-12 col-lg-6 col-md-6">
                                   <h6>oblique Leg Lift: <span className="level2">Level 2</span></h6>
                                   <p>This is dummy copy. It is not meant to be read. It has been placed here solely to demonstrate the look and feel of finished, typeset text.</p>
                                   <p> <span className="ten">Reps:</span> 12</p>

                                    <p> <span className="ten">Time: </span> 5 min</p>
                                </div>
                             </div>
                          </span>
                            </label>
                        </li>
                    </ul>
                </div>
            </div>
            <div className="row mt-50">
                <div className="col-12 col-md-6 col-lg-6 " id="checknew">
                    <div className="row">

                        <div className="col-12 col-md-12 col-lg-12">
                            <div className="heading">

                                Equipment Options

                            </div>
                            <ul className="checkbox_list pd-list">
                                <li>
                                    <label className="container-checkbox2" id="ex1">
                                        <input id="firstbox" type="checkbox"  value="1" name="fooby[]"/>
                          <span className=" checkmark main_check">
                             <div className="row">
                                <div className="col-12 col-lg-6 col-md-6">
                                   <div className="box_levelimg">
                                     <video width="100%" controls="">
  <source src="videos/cyclingvid2.mp4" type="video/mp4"/>
  Your browser does not support HTML video.
</video>
                                   </div>
                                </div>
                                <div className="col-12 col-lg-6 col-md-6">
                                   <h6>oblique Leg Lift: <span className="level">Level 1</span></h6>
                                   <p>This is dummy copy. It is not meant to be read. It has been placed here solely to demonstrate the look and feel of finished, typeset text.</p>
                                   <p> <span className="ten">Reps:</span> 12</p>

                                    <p> <span className="ten">Time: </span> 5 min</p>
                                </div>
                             </div>
                          </span>
                                    </label>
                                </li>
                            </ul>
                        </div>
                        <div className="col-12 col-md-12 col-lg-12">
                            <ul className="checkbox_list pd-list">
                                <li>
                                    <label className="container-checkbox2" id="ex1">
                                        <input id="firstbox" type="checkbox"  value="1" name="fooby[]"/>
                          <span className=" checkmark main_check">
                             <div className="row">
                                <div className="col-12 col-lg-6 col-md-6">
                                   <div className="box_levelimg">
                                     <video width="100%" controls="">
  <source src="videos/video-1649785711.mp4" type="video/mp4"/>
  Your browser does not support HTML video.
</video>
                                   </div>
                                </div>
                                <div className="col-12 col-lg-6 col-md-6">
                                   <h6>oblique Leg Lift: <span className="level">Level 1</span></h6>
                                   <p>This is dummy copy. It is not meant to be read. It has been placed here solely to demonstrate the look and feel of finished, typeset text.</p>
                                   <p> <span className="ten">Reps:</span> 12</p>

                                    <p> <span className="ten">Time: </span> 5 min</p>
                                </div>
                             </div>
                          </span>
                                    </label>
                                </li>
                            </ul>
                        </div>
                        <div className="col-12 col-md-12 col-lg-12">
                            <ul className="checkbox_list pd-list">
                                <li>
                                    <label className="container-checkbox2" id="ex1">
                                        <input id="firstbox" type="checkbox"  value="1" name="fooby[]"/>
                          <span className=" checkmark main_check">
                             <div className="row">
                                <div className="col-12 col-lg-6 col-md-6">
                                   <div className="box_levelimg">
                                     <video width="100%" controls="">
  <source src="videos/video-1649785711.mp4" type="video/mp4"/>
  Your browser does not support HTML video.
</video>
                                   </div>
                                </div>
                                <div className="col-12 col-lg-6 col-md-6">
                                   <h6>oblique Leg Lift: <span className="level">Level 1</span></h6>
                                   <p>This is dummy copy. It is not meant to be read. It has been placed here solely to demonstrate the look and feel of finished, typeset text.</p>
                                   <p> <span className="ten">Reps:</span> 12</p>

                                    <p> <span className="ten">Time: </span> 5 min</p>
                                </div>
                             </div>
                          </span>
                                    </label>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-md-6 col-lg-6 " id="checknew">
                    <div className="row">

                        <div className="col-12 col-md-12 col-lg-12">
                            <div className="heading">

                                Equipment Options

                            </div>
                            <ul className="checkbox_list pd-list">
                                <li>
                                    <label className="container-checkbox2" id="ex1">
                                        <input id="firstbox" type="checkbox"  value="1" name="fooby[]"/>
                          <span className=" checkmark main_check">
                             <div className="row">
                                <div className="col-12 col-lg-6 col-md-6">
                                   <div className="box_levelimg">
                                     <video width="100%" controls="">
  <source src="videos/video-1649785711.mp4" type="video/mp4"/>
  Your browser does not support HTML video.
</video>
                                   </div>
                                </div>
                                <div className="col-12 col-lg-6 col-md-6">
                                   <h6>oblique Leg Lift: <span className="level">Level 1</span></h6>
                                   <p>This is dummy copy. It is not meant to be read. It has been placed here solely to demonstrate the look and feel of finished, typeset text.</p>
                                   <p> <span className="ten">Reps:</span> 12</p>

                                    <p> <span className="ten">Time: </span> 5 min</p>
                                </div>
                             </div>
                          </span>
                                    </label>
                                </li>
                            </ul>
                        </div>
                        <div className="col-12 col-md-12 col-lg-12">
                            <ul className="checkbox_list pd-list">
                                <li>
                                    <label className="container-checkbox2" id="ex1">
                                        <input id="firstbox" type="checkbox"  value="1" name="fooby[]"/>
                          <span className=" checkmark main_check">
                             <div className="row">
                                <div className="col-12 col-lg-6 col-md-6">
                                   <div className="box_levelimg">
                                      <video width="100%" controls="">
  <source src="videos/video-1649785711.mp4" type="video/mp4"/>
  Your browser does not support HTML video.
</video>
                                   </div>
                                </div>
                                <div className="col-12 col-lg-6 col-md-6">
                                   <h6>oblique Leg Lift: <span className="level">Level 1</span></h6>
                                   <p>This is dummy copy. It is not meant to be read. It has been placed here solely to demonstrate the look and feel of finished, typeset text.</p>
                                   <p> <span className="ten">Reps:</span> 12</p>

                                    <p> <span className="ten">Time: </span> 5 min</p>
                                </div>
                             </div>
                          </span>
                                    </label>
                                </li>
                            </ul>
                        </div>
                        <div className="col-12 col-md-12 col-lg-12">
                            <ul className="checkbox_list pd-list">
                                <li>
                                    <label className="container-checkbox2" id="ex1">
                                        <input id="firstbox" type="checkbox"  value="1" name="fooby[]"/>
                          <span className=" checkmark main_check">
                             <div className="row">
                                <div className="col-12 col-lg-6 col-md-6">
                                   <div className="box_levelimg">
                                     <video width="100%" controls="">
  <source src="videos/video-1649785711.mp4" type="video/mp4"/>
  Your browser does not support HTML video.
</video>
                                   </div>
                                </div>
                                <div className="col-12 col-lg-6 col-md-6">
                                   <h6>oblique Leg Lift: <span className="level">Level 1</span></h6>
                                   <p>This is dummy copy. It is not meant to be read. It has been placed here solely to demonstrate the look and feel of finished, typeset text.</p>
                                   <p> <span className="ten">Reps:</span> 12</p>

                                    <p> <span className="ten">Time: </span> 5 min</p>
                                </div>
                             </div>
                          </span>
                                    </label>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    <div className="container">
        <div className="row">
            <div className="col-12 col-lg-12 col-md-12 level01">

                <h4>Want to select a diffrent level?</h4>
                <p>If you select an exercise, it will replace the original exercise in your program. </p>
                <p>You can change the exercise levels at any time.</p>
            </div>
            <div className="col-12 col-md-6 col-lg-6">
                <ul className="checkbox_list pd-list">
                    <li>
                        <label className="container-checkbox">
                            <input type="checkbox"  value="1" name="fooby[]" />
                            <span className=" checkmark main_check">
                                <div className="row">
                                 <div className="col-12 col-lg-6 col-md-6">
                                     <div className="box_levelimg">
                                        <img src={level1} alt="level1" className="img-fluid" />
                                     </div>
                                 </div>
                                <div className="col-12 col-lg-6 col-md-6">
                                    <h6>oblique Leg Lift: <span className="level">Level 1</span></h6>
                                     <p>This is dummy copy. It is not meant to be read. It has been placed here solely to demonstrate the look and feel of finished, typeset text.</p>
                                     <p> <span className="ten">Reps:</span> 12</p>
                                     <p> <span className="ten">Sets:   </span> 3</p>
                                     <p> <span className="ten">Time: </span> 5 min</p>
                                </div>
                                    </div>
                            </span>
                        </label>
                    </li>


                </ul>
            </div>
            <div className="col-12 col-md-6 col-lg-6">
                <ul className="checkbox_list pd-list">
                    <li>
                        <label className="container-checkbox">
                            <input type="checkbox" value="1" name="fooby[]" />
                            <span className=" checkmark main_check">
                                <div className="row">
                                 <div className="col-12 col-lg-6 col-md-6">
                                     <div className="box_levelimg">
                                        <img src={level2} alt="level2" className="img-fluid" />
                                     </div>
                                 </div>
                                <div className="col-12 col-lg-6 col-md-6">
                                    <h6>oblique Leg Lift: <span className="level2">Level 2</span></h6>
                                     <p>This is dummy copy. It is not meant to be read. It has been placed here solely to demonstrate the look and feel of finished, typeset text.</p>
                                     <p> <span className="ten">Reps:</span> 12</p>
                                     <p> <span className="ten">Sets:   </span> 3</p>
                                     <p> <span className="ten">Time: </span> 5 min</p>
                                </div>
                                    </div>
                            </span>
                        </label>
                    </li>
                </ul>
            </div>
        </div>
        </div>
</section>
  )
}

export default ChangeLevel2