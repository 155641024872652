import React from 'react'


import Header from "../../Layout/Header"
import Footer from "../../Layout/Footer"
import Welcome1 from './Welcome1'
import ChangeLevel1 from './ChangeLevel1'
import Welcome2 from './Welcome2'
import ChangeLevel2 from './ChangeLevel2'

const ChangeLevel = () => {
  return (
    <div>
    <Header />
   <Welcome1 />
<ChangeLevel1 />
<Welcome2 />
<ChangeLevel2 />
<Footer />
</div>
  )
}

export default ChangeLevel