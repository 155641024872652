import React, {useEffect} from 'react'
import WeekSlider from './WeekSlider'
import Header from '../../Layout/Header'
import Banner from './Banner'
import BreadcrumbMenu from './BreadcrumbMenu'
import Exercises from './Exercises'
import Footer from '../../Layout/Footer'
import PropTypes from "prop-types";
import {connect} from "react-redux";
import Exercise from "./Exercise";
import ScrollTopOnMount from "../../Layout/ScrollTopOnMount";


const Index = () => {
    // useEffect(()=>{
    //     const myRef = React.createRef()
    //     myRef.current.scrollTo(0, 0)
    // },[])
    return (
        <>
            <ScrollTopOnMount/>
            <Header/>
            <Banner/>
            <WeekSlider/>
            <Footer/>
        </>
    )
}

// Index.propTypes = {
//     getWeekSchedule : PropTypes.func.isRequired,
//
// };

// const mapStateToProps = state => ({
// })
export default connect(
    null,
    {}
)(Index);
