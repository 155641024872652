import React from "react";
import Modal from "react-bootstrap/Modal";
import {useState} from "react";
import {Link} from "react-router-dom";

const EducationVideoModal = ({show, handleClose, test_details}) => {
    return (
        <>
            <Modal show={show} onHide={handleClose} className="modal_test vedio_modal" centered>
                <Modal.Header>

                    <button
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                        onClick={handleClose}
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </Modal.Header>
                <Modal.Body className="modal-body">
                    <video
                        className="video"
                        controls="controls"
                        width="100%"
                        height="100%"
                        poster={test_details.image}
                    >
                        <source src={test_details.educational_video}/>
                    </video>

                </Modal.Body>
            </Modal>
        </>
    );
};

export default EducationVideoModal;
