import React from 'react'
import { Link } from 'react-router-dom'

const Welcome2 = () => {
  return (
    <section className="welcome_002">
    <div className="container">
        <div className="row align-items-center">
            <div className="col-12 col-lg-12 col-md-12">
                <ul className="breadcrumb main">
                    <li className=""><Link to="/">Home </Link></li>
                    <li><Link to="/welcome"> Welcome</Link></li>
                    <li><Link to="#"> Assestment</Link></li>
                    <li><Link to="/assessment-timer4"> Day 1</Link></li>
                    <li className="active"><Link to="/change-level"> Level Change</Link></li>
                </ul>
            </div>
        </div>
    </div>
</section>
  )
}

export default Welcome2