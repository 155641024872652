import React from 'react'

const Home11 = () => {
  return (
    <section className="home_0011">
    <div className="container">
        <div className="row">
            <div className="col-12 col-lg-6 col-md-8">
                <div className="sec01 pb-50">
                    <h2>Your Body Type</h2>
                    <p>Our assessment may identify</p>
                    <p>Most people’s imbalances fall into 3 different body types.</p>
                    <p className="p-small">(which may include common variations)</p>
                </div>
                <div className="sec01">
                    <h2>Each body type
                        has similar:</h2>
                    <p>-Over-active (dominant) muscles</p>
                    <p>-Under-activate (lengthened) muscles</p>

                </div>
            </div>

        </div>
    </div>
</section>
  )
}

export default Home11