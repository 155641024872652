import React from 'react'
import {Link} from 'react-router-dom'
const Welcome2 = () => {
  return (
    <section className="welcome_002">
    <div className="container">
        <div className="row align-items-center">
            <div className="col-12 col-lg-8 col-md-8">
                <ul className="breadcrumb main">
                    <li className=""><Link to="/">Home </Link></li>
                    <li><Link to="#"> Welcome</Link></li>
                    <li><Link to="#"> Assestment</Link></li>
                    <li><Link to="#"> Day 1</Link></li>
                    <li className="active"><Link to="#"> Test</Link></li>
                </ul>
            </div>
            <div className="col-12 col-lg-4 col-md-4">
                <div className="nav_week">
                    <Link to="assestment-day1.php" className="daily">Daily</Link>
                    <div className="button-switch">
                        <input type="checkbox" id="addsetting"
                               className="switch sw_btn" checked="true"/>
                        <label htmlFor="addsetting" className="lbl-off"> </label>
                    </div>
                    <Link to="/weekly" className="weekly">Weekly</Link>
                </div>
            </div>
        </div>
    </div>
</section>
  )
}

export default Welcome2