import React from 'react'
import Modal from "react-bootstrap/Modal";
import Carousel from "react-bootstrap/Carousel";

const HowToUseModal = ({show, handleClose}) => {
    // const [show, setShow] = useState(false);


    return (
        <>
            <Modal show={show}
                   onHide={handleClose}
                   id="IntroModal"
                   className="modal fade"
                   aria-hidden="true"
                   aria-labelledby="exampleModalLabel"
                   centered>

                <Modal.Body>
                    <Modal.Header>
                        <Modal.Title>How To Use This App?</Modal.Title>
                        <button
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                            onClick={handleClose}
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </Modal.Header>
                    <Carousel>
                        <Carousel.Item>
                            <img
                                className="img-fluid"
                                src="images/steps/step01.png"
                                alt="First slide"
                            />
                        </Carousel.Item>
                        <Carousel.Item>
                            <img
                                className="img-fluid"
                                src="images/steps/step02.png"
                                alt="Second slide"
                            />
                        </Carousel.Item>
                        <Carousel.Item>
                            <img
                                className="img-fluid"
                                src="images/steps/step03.png"
                                alt="Third slide"
                            />
                        </Carousel.Item>
                        <Carousel.Item>
                            <img
                                className="img-fluid"
                                src="images/steps/step04.png"
                                alt="Third slide"
                            />
                        </Carousel.Item>
                        <Carousel.Item>
                            <img
                                className="img-fluid"
                                src="images/steps/step05.png"
                                alt="Third slide"
                            />
                        </Carousel.Item>
                        <Carousel.Item>
                            <img
                                className="img-fluid"
                                src="images/steps/step06.png"
                                alt="Third slide"
                            />
                        </Carousel.Item>
                    </Carousel>

                </Modal.Body>

            </Modal></>
    )
}

export default HowToUseModal