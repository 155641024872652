import React from 'react'
import DayCompleteModal from './DayCompleteModal'

const Assessment3 = () => {
  return (
    <section className="assestmentd1_003">
    <div className="container">
        <div className="row">
            <div className="col-12 col-lg-12 col-md-12">
                <h4>Excerises</h4>

            </div>
            <div className="col-12 col-lg-4 col-md-4">
                <div className="box_p shadow_box">
                    <div className="img_box">
                        <img src="/images/img.png" alt='img' className="img-fluid" />
                    </div>
                    <h6>Ankle Scraping</h6>
                    <ul className="time_list">
                        <li><span className="red">Suggested Time: </span> <span className="time">60 Sec</span></li>
                        <li><span className="red">Actual Time:  </span> <span className="time">50 Sec</span></li>
                    </ul>
                </div>
            </div>
            <div className="col-12 col-lg-4 col-md-4">
                <div className="box_p shadow_box">
                    <div className="img_box">
                        <img src="images/img.png" alt="img" className="img-fluid" />
                    </div>
                    <h6>Calf Board Stretch Single Leg</h6>
                    <ul className="time_list">
                        <li><span className="red">Suggested Time: </span> <span className="time">60 Sec</span></li>
                        <li><span className="red">Actual Time:  </span> <span className="time">50 Sec</span></li>
                    </ul>
                </div>
            </div>
            <div className="col-12 col-lg-4 col-md-4">
                <div className="box_p shadow_box">
                    <div className="img_box">
                        <img src="/images/img.png" alt='img' className="img-fluid" />
                    </div>
                    <h6>Thoracic Spine Release</h6>
                    <ul className="time_list">
                        <li><span className="red">Suggested Time: </span> <span className="time">60 Sec</span></li>
                        <li><span className="red">Actual Time:  </span> <span className="time">50 Sec</span></li>
                    </ul>
                </div>
            </div>
            <div className="col-12 col-lg-4 col-md-4">
                <div className="box_p shadow_box">
                    <div className="img_box">
                        <img src="/images/img.png" alt='img' className="img-fluid" />
                    </div>
                    <h6>Plank Step</h6>
                    <ul className="time_list">
                        <li><span className="red">Suggested Time: </span> <span className="time">60 Sec</span></li>
                        <li><span className="red">Actual Time:  </span> <span className="time">50 Sec</span></li>
                    </ul>
                </div>
            </div>
            <div className="col-12 col-lg-4 col-md-4">
                <div className="box_p shadow_box">
                    <div className="img_box">
                        <img src="/images/img.png" alt='img' className="img-fluid" />
                    </div>
                    <h6>Calf Board Stretch Single Leg</h6>
                    <ul className="time_list">
                        <li><span className="red">Suggested Time: </span> <span className="time">60 Sec</span></li>
                        <li><span className="red">Actual Time:  </span> <span className="time">50 Sec</span></li>
                    </ul>
                </div>
            </div>
            <div className="col-12 col-lg-4 col-md-4">
                <div className="box_p shadow_box">
                    <div className="img_box">
                        <img src="/images/img.png" alt='img' className="img-fluid" />
                    </div>
                    <h6>Thoracic Spine Release</h6>
                    <ul className="time_list">
                        <li><span className="red">Suggested Time: </span> <span className="time">60 Sec</span></li>
                        <li><span className="red">Actual Time:  </span> <span className="time">50 Sec</span></li>
                    </ul>
                </div>
            </div>
            <div className="col-12 col-lg-4 col-md-4">
                {/* <Link to="" className="theme_btn1" data-toggle="modal" data-target="#completion">Finish</Link> */}
                <DayCompleteModal />
            </div>
        </div>
    </div>
</section>
  )
}

export default Assessment3