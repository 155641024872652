import React, {useEffect} from "react";
import Footer from "../../Layout/Footer";
import Header from "../../Layout/Header";
import Day1 from "./Day1";
import Welcome1 from "./Welcome1";
import Welcome2 from "./Welcome2";
import TestDetails from "./TestDetails";
import ScrollTopOnMount from "../../Layout/ScrollTopOnMount";
import {useLocation, useParams} from "react-router-dom";
import {connect} from "react-redux";
import {getProgram, getProgramTest, getUserProgramDay} from "../../../actions/user_program";

const Index = ({getProgram, program, getUserProgramDay, getProgramTest, user_program_day, test_details}) => {
    let {id} = useParams();
    useEffect(() => {
        if (!program) {
            getProgram()
        }
        if (localStorage.getItem('activeDayId')) {
            getUserProgramDay(localStorage.getItem('activeDayId'))
        }
        getProgramTest(id)
    }, [])
    const location = useLocation();
    let selectedTests = location.state && location.state.selectedTests ? location.state.selectedTests : null
    if (selectedTests) {
        localStorage.setItem("selectedTests", JSON.stringify(selectedTests))
    }
    return (
        <>
            <ScrollTopOnMount/>
            <Header/>
            <Welcome1 program={program} user_program_day={user_program_day}/>
            <Day1/>
            <Welcome2 test_details={test_details} program={program}/>
            <TestDetails test_details={test_details}/>
            <Footer/>
        </>
    );
};
const mapStateToProps = state => ({
    program: state.programReducer.program,
    test_details: state.programReducer.test_details,
    user_program_day: state.programReducer.user_program_day
});

export default connect(mapStateToProps, {getProgram, getProgramTest, getUserProgramDay})(Index);
