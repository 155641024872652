import React, {useEffect} from 'react';
import {Navigate, Outlet} from 'react-router-dom';

const ProtectedRoute = ({
                            redirectPath = '/login',
                            children,
                        }) => {

    
    let user = JSON.parse(localStorage.getItem('user'));
    if (!user) {
        return <Navigate to={redirectPath} replace/>;
    }

    return children ? children : <Outlet/>;
};

export default ProtectedRoute