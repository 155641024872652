import React, {useEffect, useLayoutEffect, useState} from "react";
import Header from "../../Layout/Header";
import Footer from "../../Layout/Footer";
import Welcome1 from './Welcome1'
import Welcome3 from './Welcome3'
import {connect} from "react-redux";
import PropTypes from 'prop-types';
import {getProgram} from "../../../actions/user_program";
import ScrollTopOnMount from "../../Layout/ScrollTopOnMount";
import HowToUseModal from "../../Layout/HowToUseModal";


const Welcome = ({getProgram}) => {
    const [show, setShow] = useState(false);
    useEffect(() => {
        getProgram()
        handleShow()
    }, [])
    const handleShow = () => {
        setShow(true)
    };
    const handleClose = () => setShow(false);
    return (
        <div>
            <ScrollTopOnMount/>
            <HowToUseModal handleClose={handleClose} show={show}/>
            <Header/>
            <Welcome1/>
            <Welcome3/>
            <Footer/>
        </div>
    );
};

Welcome.propTypes = {
    getProgram: PropTypes.func.isRequired
};
export default connect(
    null,
    {getProgram}
)(Welcome);
