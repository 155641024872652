import React from 'react'

const Home1 = () => {
  return (
    <section className="home_001">
    <div className="container h-100">
        <div className="row h-100">
            <div className="col-12 col-lg-4 col-md-5 my-auto">
                <div className="box_banner">
                    <h2>Is
                        <span>PAIN</span>
                        holding
                        you back?</h2>
                </div>
            </div>
        </div>
    </div>
</section>
  )
}

export default Home1