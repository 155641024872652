import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {getExerciseDetail} from "../../../actions/user_program";
import {FadeLoader} from "react-spinners";
import {Link, useParams} from "react-router-dom";
import {Interweave} from "interweave";


const Exercises = ({ownProps, getExerciseDetail, exercises, weekSchedule}) => {
    let {activeDayId} = ownProps
    const [showExercise, setShowExercise] = useState(null)
    const [isActive, setIsActive] = useState(false);
    useEffect(() => {
        if (activeDayId) {
            getExerciseDetail(activeDayId)
        }
    }, [])

    useEffect(() => {
        if (exercises && exercises.length > 0) {
            setShowExercise(exercises[0])
        }
    }, [exercises])
    const handleClick = () => {
        setIsActive((current) => !current);
    };
    const handleShowExercise = (exercise) => {
        setShowExercise(exercise)
    }
    if (!exercises) {
        return (
            <div className="container">
                <div className="row">
                    <div className="col-12 center-loader">
                        <FadeLoader color={"#FB1417"} height={10}/>
                    </div>
                </div>
            </div>
        )
    }
    return (
        <>
            <section className="grid_001">
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-lg-6 col-md-6  ">
                            {/* <button onClick={()=> setOpenModal(!openModal)}><h1>MODAL</h1></button> */}
                            {/* {openModal && <StatusCheckinModal />} */}
                            <ul
                                className="nav nav-tabs row left_tabs slider_mobile"
                                role="tablist"
                            >
                                {exercises.map((item, key) => <li key={key} className="nav-item col-6 col-lg-6">
                                    <Link
                                        className={showExercise && item.id == showExercise.id ? "nav-link active" : "nav-link"}
                                        data-toggle="tab"
                                        to="#tabs-1"
                                        role="tab"
                                        onClick={() => {
                                            handleShowExercise(item)
                                        }}
                                    >
                                        <div className="box_levelimg1">
                                            <img
                                                src={item.exercise.body_part}
                                                alt="img41"
                                                className="img-fluid"
                                            />
                                        </div>
                                        <div className="title_box">
                                            <h6>{item.exercise.name}</h6>
                                            <div className="icon_level">
                                                <svg
                                                    width="37"
                                                    height="37"
                                                    viewBox="0 0 37 37"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <circle
                                                        cx="18.1211"
                                                        cy="18.3081"
                                                        r="18"
                                                        fill="black"
                                                    ></circle>
                                                    <rect
                                                        x="10.9209"
                                                        y="18.6885"
                                                        width="3.32275"
                                                        height="9.53105"
                                                        fill="#1AE074"
                                                    ></rect>
                                                    <rect
                                                        x="15.9927"
                                                        y="13.5295"
                                                        width="3.32275"
                                                        height="14.6901"
                                                        fill="#9B9B9B"
                                                    ></rect>
                                                    <rect
                                                        x="21.4141"
                                                        y="8.10806"
                                                        width="3.32275"
                                                        height="20.1114"
                                                        fill="#9B9B9B"
                                                    ></rect>
                                                </svg>
                                            </div>
                                        </div>
                                    </Link>
                                </li>)}
                            </ul>
                        </div>
                        {showExercise ? <div className="col-12 col-lg-6 col-md-6 test_one">
                            <div className="tab-content box_test ex_box">
                                <div className="tab-pane active" id="tabs-1" role="tabpanel">
                                    <div className="">
                                        <div className="row">
                                            <div className="col-9 col-md-7">
                                                <h4 className="mb-20">{showExercise.exercise.name}</h4>
                                            </div>
                                            <div className="col-3 col-md-5 text-right">
                                                <div className="btn-group ">
                                                    <div className="icon_bar" data-toggle="dropdown">

                                                        <svg width="37" height="37" viewBox="0 0 37 37" fill="none"
                                                             xmlns="http://www.w3.org/2000/svg">
                                                            <circle cx="18.1211" cy="18.3081" r="18"
                                                                    fill="black"></circle>
                                                            <rect x="10.9209" y="18.6885" width="3.32275"
                                                                  height="9.53105" fill="#1AE074"></rect>
                                                            <rect x="15.9927" y="13.5295" width="3.32275"
                                                                  height="14.6901" fill="#9B9B9B"></rect>
                                                            <rect x="21.4141" y="8.10806" width="3.32275"
                                                                  height="20.1114" fill="#9B9B9B"></rect>
                                                        </svg>


                                                    </div>
                                                    <div className="dropdown-menu dropdown-menu-right bar_dropdown">
                                                        <h4>Exercise Levels</h4>
                                                        <ul className="chart01  ">
                                                            <li><span></span> <i><b>Level 1</b> Easy </i>
                                                            </li>
                                                            <li><span></span> <i><b>Level 2</b> Moderate </i></li>
                                                            <li><span></span> <i><b>Level 3</b> Advanced </i></li>

                                                        </ul>
                                                        <div id="wrapper1">

                                                            <div className="btn_chart text-center">
                                                                <a href="change-level.php"
                                                                   className="theme_btn1 small_btn">Change
                                                                    Level</a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                        <h6>Starting Position</h6>
                                        <p>
                                            <Interweave content={showExercise.exercise.short_description}/>
                                        </p>
                                        <h6>Movement</h6>
                                        <p>
                                            <Interweave content={showExercise.exercise.description}/>
                                        </p>
                                        <div className="exc_p">
                                            <p><span>Note:</span> {showExercise.exercise.note}</p>
                                        </div>
                                        <div className=" ssa box_video" style={{textAlign: 'center'}} data-click="0">
                                            <video className="video" controls="controls" width="100%" height="100%"
                                                   poster={showExercise.exerciseLevel.image}>
                                                <source src={showExercise.exerciseLevel.video}/>
                                            </video>
                                            {/* <div id="video_controls_bar">
                                                <img src="/images/puse.png" className="img-fluid img001"/>
                                            </div>*/}
                                            <div className="overlay_vid"></div>
                                        </div>


                                        <div className="  position-relative">
                                            <a href="#" className="theme_btn1" onClick={() => {
                                                document.getElementById("start-test").click()
                                            }}>
                                                START TEST </a>

                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div> : null}
                    </div>
                </div>
            </section>
        </>
    );

};
const mapStateToProps = (state, ownProps) => ({
    exerciseDetailRequest: state.programReducer.exerciseDetailRequest || null,
    exerciseDetailSuccess: state.programReducer.exerciseDetailSuccess || null,
    exerciseDetailFailure: state.programReducer.exerciseDetailFailure || null,
    exercises: state.programReducer.exercises,
    weekSchedule: state.programReducer.weekSchedule,
    ownProps: ownProps
});
export default connect(mapStateToProps, {getExerciseDetail})(Exercises);
