import Carousel from "react-bootstrap/Carousel";
import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {getExerciseDetail, getProgram, getWeekSchedule} from "../../../actions/user_program";
import {FadeLoader} from "react-spinners";
import {useParams} from "react-router-dom";
import Exercises from "./Exercises"
import BreadcrumbMenu from "./BreadcrumbMenu";


const WeekSlider = ({
                        weekScheduleRequest, weekScheduleSuccess, weekScheduleFailure,
                        programId, getWeekSchedule, getProgram, weekSchedule, getExerciseDetail
                    }) => {
    let {id} = useParams()
    const [activeDayId, setActiveDayId] = useState(null)
    useEffect(() => {
        getWeekSchedule(id)
    }, [])
    useEffect(() => {
        if (activeDayId) {
            getExerciseDetail(activeDayId)
        }
    }, [activeDayId])
    const changeDayId = (id) => {
        setActiveDayId(id)
    }
    if (!weekScheduleSuccess) {
        return <div className="container">
            <div className="row">
                <div className="col-12 center-loader">
                    <FadeLoader color={"#FB1417"} height={10}/>
                </div>
            </div>
        </div>
    }
    return (
        <>
            <section className="assestmentgrid_001">
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-lg-12 col-md-12 p-0">
                            <Carousel className="box_week" indicators={false} nextLabel='' prevLabel='' interval={null}>
                                {weekSchedule.map((item, key) => <Carousel.Item style={{"textAlign": "center"}}
                                                                                key={item.week}>
                                        <h6>Week {item.week}</h6>
                                        <div className="box_date">
                                            <ul className="date_week">
                                                {item.days.map(day => <li key={day.id}>
                                                        {day.meta.is_active ? localStorage.setItem('activeDayId', day.id) : null}
                                                        <p className={day.meta.is_active === 1 ? "active" : ""}
                                                           data-toggle="modal"
                                                           data-target="#statuscheckout1" style={{cursor: 'pointer'}}>
                                                            <span
                                                                className={day.id == activeDayId ? "day-underline" : ""}
                                                                onClick={() => changeDayId(day.id)}>{day.programDay.name}</span>
                                                        </p>
                                                    </li>
                                                )
                                                }
                                            </ul>
                                        </div>
                                    </Carousel.Item>
                                )
                                }
                            </Carousel>
                        </div>
                    </div>
                </div>
            </section>
            <BreadcrumbMenu/>
            <Exercises activeDayId={activeDayId ? activeDayId : localStorage.getItem('activeDayId')}/>
        </>
    );
};

WeekSlider.propTypes = {
    weekSchedule: PropTypes.array.isRequired,
};

const mapStateToProps = state => ({

    weekScheduleRequest: state.programReducer.weekScheduleRequest || null,
    weekScheduleSuccess: state.programReducer.weekScheduleSuccess || null,
    weekScheduleFailure: state.programReducer.weekScheduleFailure || null,
    programId: state.programReducer.programId,
    weekSchedule: state.programReducer.weekSchedule
});

export default connect(mapStateToProps, {getWeekSchedule, getProgram, getExerciseDetail})(WeekSlider);

// export default WeekSlider