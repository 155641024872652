import React from "react";
import {Link} from "react-router-dom";
import {FadeLoader} from "react-spinners";
import PropTypes from "prop-types";
import {connect} from "react-redux";


const Welcome3 = ({program, loading}) => {

    if (!program && !loading) {

        return (

            <section className="welcome_003">
                <div className="container">
                    <div className="row center-loader" style={{margin: "0 auto"}}>
                        <p>No program assigned</p>
                    </div>
                </div>
            </section>)
    }
    return (
        <>
            {!program || loading ?
                <section className="welcome_003">
                    <div className="container">
                        <div className="row center-loader">
                            <FadeLoader color={"#FB1417"} height={10}/>
                        </div>
                    </div>
                </section>
                :
                <>
                    <section className="welcome_002">
                        <div className="container">
                            <div className="row">
                                <div className="col-12 col-lg-12 col-md-12">
                                    <ul className="breadcrumb main">
                                        <li className="">
                                            <Link to="/">Home </Link>
                                        </li>
                                        <li className="active">
                                            <Link to="/welcome"> Welcome</Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="welcome_003">
                        <div className="container">
                            <div className="row">
                                <div className="col-12 col-lg-6 col-md-6">
                                    <h4>Welcome</h4>
                                    <div
                                        className=" ssa box_video"
                                        style={{"text-align": "center"}}
                                        data-click="0"
                                    >
                                        <video
                                            className="video"
                                            controls
                                            autoPlay={"autoplay"}
                                            preLoad="auto"
                                        >
                                            <source src="images/ankle-scraping.mp4" type="video/mp4"/>
                                        </video>

                                    </div>
                                </div>
                                <div className="col-12 col-lg-6 col-md-6">
                                    <h4>Your Body Type</h4>

                                    <div
                                        className=" ssa box_video"
                                        style={{"text-align": "center"}}
                                        data-click="0"
                                    >
                                        <video
                                            className="video"
                                            controls="controls"
                                            autoPlay={"autoplay"}
                                            preLoad="auto"
                                            width="100%"
                                            height="100%"
                                            loop
                                            poster="images/poster.png"
                                        >
                                            <source
                                                src="/images/Gastroc_Calf_Board_Stretch_Single_Leg.mp4"
                                                type="video/mp4"
                                            />
                                        </video>

                                        {/* <div id="video_controls_bar">
                                <img src="/images/puse.png" alt="puse" className="img-fluid img001" />
                            </div>
                      <div className="overlay_vid"></div> */}
                                    </div>
                                </div>
                                <div className="col-12 col-lg-12 col-md-12 text-center">
                                    <Link to={`/program/${program.id}`} className="theme_btn1">
                                        Start your program
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </section>
                </>
            }
        </>
    );
};

Welcome3.propTypes = {
    program: PropTypes.object.isRequired,
    loading: PropTypes.bool.isRequired
};

const mapStateToProps = state => ({
    program: state.programReducer.program,
    loading: state.programReducer.loading
});

export default connect(mapStateToProps, {})(Welcome3);
