import React from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

const PrivateRoute = ({ component: Component, auth: { isAuthenticated } }) => {
   console.log(isAuthenticated, "isAuthenticated")
    const navigate = useNavigate()

    if (!isAuthenticated) {
        navigate("/login", {replace:true})
    }
    return <Component />;

};

PrivateRoute.propTypes = {
    auth: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
    auth: state.auth
});

export default connect(mapStateToProps, {})(PrivateRoute);
