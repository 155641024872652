import React from 'react'

const Home9 = () => {
  return (
    <section className="home_009">
    <div className="container">
        <div className="row">
            <div className="col-12 col-lg-6 col-md-7 m-auto">
                <div className="text-center">
                    <h2>Body Mapping
                    </h2>
                    <p>Uses dots and lines to help identify which<br />
                        muscles are working too hard and which muscles are<br />
                        not working hard enough.</p>
                </div>
            </div>
        </div>
    </div>
</section>
  )
}

export default Home9