import React from "react";
import { Link } from "react-router-dom";
import { useState } from "react";
import barchart from "../images/barchart.png";
import Footer from "../Layout/Footer";
import Header from "../Layout/Header";
import Countdown from "react-countdown";

const AssessmentTimer2 = () => {
  // Renderer callback with condition
  const renderer = ({ minutes, seconds, completed }) => {
    if (completed) {
      // Render a completed state
      return <h1>completed</h1>;
    } else {
      // Render a countdown
      return (
        <span>
          {minutes} minutes : {seconds} seconds
        </span>
      );
    }
  };
  const [isActive, setIsActive] = useState(false);

  const handleClick = (event) => {
    // 👇️ toggle isActive state on click
    setIsActive((current) => !current);
  };
  return (
    <>
      <Header />
      <section className="welcome_001">
        <div className="container h-100">
          <div className="row h-100">
            <div className="col-12 col-lg-6 col-md-6 m-auto">
              <div className="box_banner_inner text-center">
                <div>
                  <h1>Anterior Pelvic Tilt</h1>
                  <h5>with Right Hip Drop</h5>
                  <p>12 Week Corrective Exercise Program</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="assestmenttimwe_001">
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-12 col-md-12">
              <ul className="timer_step">
                <li className="active">
                  <p>Ankle Scraping</p>
                </li>
                <li>
                  <p>Ankle Scraping</p>
                </li>
                <li>
                  <p>Ankle Scraping</p>
                </li>
                <li>
                  <p>Ankle Scraping</p>
                </li>
                <li>
                  <p>Ankle Scraping</p>
                </li>
                <li>
                  <p>Ankle Scraping</p>
                </li>
                <li>
                  <p>Ankle Scraping</p>
                </li>
                <li>
                  <p>Ankle Scraping</p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <section className="welcome_002">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-12 col-lg-12 col-md-12">
              <ul className="breadcrumb main">
                <li className="">
                  <Link to="/">Home </Link>
                </li>
                <li>
                  <Link to="/welcome"> Welcome</Link>
                </li>
                <li>
                  <Link to="/assessment-gridview"> Assestment</Link>
                </li>
                <li className="active">
                  <Link to="/assessment-timer4"> Day 1</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <section className="test1_001">
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-6 col-md-6  ">
              <div
                className=" ssa box_video"
                style={{ textAlign: "center" }}
                data-click="0"
              >
                <video
                  className="video"
                  controls="controls"
                  width="100%"
                  height="100%"
                  poster="images/poster.png"
                  autoPlay={true}
                >
                  <source src="images/ankle-scraping.mp4" />
                </video>
                <div id="video_controls_bar">
                  {/* <img
                    src="../../images/puse.png"
                    alt="puse"
                    className="img-fluid img001"
                  /> */}
                </div>
              </div>
              <div className="timer_box">
                <ul>
                  <li>
                    <svg
                      width="27"
                      height="15"
                      viewBox="0 0 27 15"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M26.0124 5.99222C25.9115 5.78411 25.8169 5.56338 25.5205 5.60753C25.3061 5.63906 25.1484 5.84717 25.1484 6.1688C25.1358 7.2346 25.1421 8.29409 25.1484 9.35988C25.1484 9.43556 25.161 9.50493 25.1799 9.5743C25.2367 9.75719 25.3439 9.90224 25.5457 9.92116C25.7412 9.94008 25.8673 9.82656 25.9493 9.6626C25.9682 9.61845 25.9935 9.5743 26.0124 9.53647C26.0124 8.35085 26.0124 7.17154 26.0124 5.99222Z"
                        fill="#9B9B9B"
                      />
                      <path
                        d="M21.4528 7.75174C21.4528 5.97331 21.4465 4.19489 21.4528 2.41646C21.4528 1.81734 21.2889 1.29391 20.8033 0.92813C20.0465 0.35424 19.0059 0.72632 18.6717 1.6786C18.5834 1.93086 18.5645 2.18942 18.5645 2.4543C18.5645 3.70928 18.5582 4.95797 18.5708 6.21296C18.5708 6.38954 18.5329 6.4463 18.3437 6.4463C14.9824 6.43999 11.6273 6.43999 8.26599 6.4463C8.06418 6.4463 8.04526 6.37693 8.04526 6.21296C8.05157 4.95166 8.05157 3.69037 8.05157 2.42907C8.05157 1.83626 7.8876 1.31282 7.402 0.934435C6.64522 0.354239 5.56681 0.745241 5.2641 1.70383C5.20104 1.89302 5.1632 2.08222 5.1632 2.28402C5.1632 5.94809 5.15689 9.60584 5.1695 13.2699C5.1695 13.9195 5.4533 14.4492 6.05241 14.7456C6.80288 15.1114 7.71102 14.651 7.96328 13.7933C8.03265 13.5537 8.05787 13.3141 8.05787 13.0681C8.05787 11.8131 8.05787 10.5644 8.05157 9.30944C8.05157 9.14547 8.0831 9.08871 8.25968 9.08871C11.6273 9.09502 14.995 9.09502 18.3627 9.08871C18.5392 9.08871 18.5771 9.14547 18.5771 9.30944C18.5771 10.6843 18.5645 12.0591 18.596 13.4402C18.6149 14.3231 19.3906 14.96 20.2231 14.8528C20.8348 14.7772 21.3898 14.1465 21.4402 13.4654C21.4528 13.3204 21.4528 13.169 21.4528 13.024C21.4528 11.2581 21.4528 9.50494 21.4528 7.75174Z"
                        fill="#9B9B9B"
                      />
                      <path
                        d="M4.5517 7.73914C4.5517 6.50938 4.5517 5.27962 4.5517 4.04985C4.5517 3.44443 4.28683 3.04712 3.79492 2.88946C2.95616 2.62459 2.23092 3.15433 2.23092 4.04354C2.22461 6.3391 2.23092 8.62836 2.23092 10.9239C2.23092 11.2077 2.20569 11.4978 2.25614 11.7816C2.35705 12.3555 2.82372 12.7023 3.47329 12.6771C4.04088 12.6582 4.50125 12.2609 4.52647 11.7122C4.55801 11.0185 4.54539 10.3185 4.5517 9.62478C4.55801 9.00044 4.5517 8.36979 4.5517 7.73914Z"
                        fill="#9B9B9B"
                      />
                      <path
                        d="M24.4613 7.75805C24.4613 6.50306 24.4613 5.25437 24.4613 3.99938C24.4613 3.50117 24.2595 3.11017 23.7928 2.92728C23.3387 2.7507 22.891 2.78223 22.5063 3.11648C22.2477 3.34351 22.1405 3.63992 22.1405 3.96785C22.1405 6.49044 22.1342 9.01304 22.1405 11.5356C22.1405 12.1347 22.5315 12.5888 23.0676 12.6645C23.7171 12.7591 24.2342 12.4627 24.4108 11.9014C24.4613 11.7437 24.4613 11.5798 24.4613 11.4158C24.4613 10.1987 24.4613 8.98151 24.4613 7.75805Z"
                        fill="#9B9B9B"
                      />
                      <path
                        d="M0.843262 7.75806C0.843262 8.25627 0.843262 8.75449 0.843262 9.259C0.843262 9.38513 0.843262 9.50496 0.893714 9.61847C0.969392 9.79506 1.08921 9.92749 1.29733 9.91488C1.50544 9.90227 1.61896 9.75721 1.67572 9.58063C1.70725 9.46712 1.71356 9.34099 1.71356 9.21486C1.71356 8.24366 1.71356 7.26616 1.71356 6.29496C1.71356 6.17514 1.70725 6.04901 1.67572 5.92918C1.61896 5.7463 1.50544 5.60755 1.29733 5.59494C1.08921 5.58233 0.969392 5.72107 0.893714 5.89765C0.849568 6.00486 0.843262 6.11838 0.843262 6.2382C0.843262 6.74902 0.843262 7.25354 0.843262 7.75806Z"
                        fill="#9B9B9B"
                      />
                    </svg>
                    <h6>Dumbbells</h6>
                    <p>10-15lbs</p>
                  </li>
                  <li>
                    <svg
                      width="21"
                      height="21"
                      viewBox="0 0 21 21"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M10.0722 0.866455C4.54769 0.866455 0.0693359 5.34481 0.0693359 10.8693C0.0693359 16.3938 4.54769 20.8722 10.0722 20.8722C15.5967 20.8722 20.0751 16.3938 20.0751 10.8693C20.0751 5.34481 15.5967 0.866455 10.0722 0.866455ZM14.1383 14.1624C13.8858 14.4716 13.4529 14.5025 13.1076 14.2294C11.9635 13.3172 10.8298 12.405 9.68569 11.4929C9.46409 11.3177 9.35587 11.1012 9.36102 10.8126C9.36617 9.90047 9.36102 8.98315 9.36102 8.07099C9.36102 7.12275 9.36102 6.17967 9.36102 5.23143C9.36102 4.90161 9.58777 4.62848 9.88667 4.55633C10.201 4.48418 10.5205 4.61817 10.6752 4.89646C10.7525 5.0356 10.7628 5.19021 10.7628 5.34481C10.7628 7.01453 10.7628 8.6791 10.7576 10.3488C10.7576 10.5034 10.8091 10.591 10.9225 10.6838C11.9429 11.498 12.9633 12.3123 13.9837 13.1317C14.3444 13.41 14.4011 13.8377 14.1383 14.1624Z"
                        fill="#9B9B9B"
                      />
                    </svg>
                    <h6>Seconds</h6>
                    <p>45s</p>
                  </li>
                  <li>
                    <svg
                      width="15"
                      height="25"
                      viewBox="0 0 15 25"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect
                        x="0.853027"
                        y="16.9424"
                        width="2.45446"
                        height="7.36337"
                        fill="#9B9B9B"
                      />
                      <rect
                        x="6.23291"
                        y="8.96558"
                        width="2.45446"
                        height="15.3404"
                        fill="#9B9B9B"
                      />
                      <rect
                        x="11.6133"
                        y="0.988525"
                        width="2.45446"
                        height="23.3173"
                        fill="#9B9B9B"
                      />
                    </svg>

                    <h6>Level</h6>
                    <p className="level">Easy</p>
                  </li>
                  <li>
                    <Link
                      to="/images/ankle-scraping.mp4"
                      className="edu popup-youtube d-block"
                    >
                      <svg
                        width="26"
                        height="22"
                        viewBox="0 0 26 22"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M25.7266 5.55698C25.5718 5.86026 25.3072 6.00867 24.991 6.11837C21.2355 7.44118 17.48 8.7769 13.7309 10.1126C13.4664 10.2094 13.2276 10.203 12.9566 10.1126C9.1624 8.75755 5.36172 7.41537 1.56104 6.0732C1.28357 5.97641 1.1029 5.79573 0.960938 5.56343C0.960938 5.42147 0.960938 5.27305 0.960938 5.13109C1.01256 4.87298 1.18678 4.71812 1.41908 4.61487C1.76753 4.46001 2.12889 4.35676 2.49024 4.22771C5.16814 3.2856 7.83958 2.3435 10.5175 1.40785C11.3176 1.13038 12.1113 0.846462 12.9114 0.562541C13.2083 0.459296 13.4922 0.459296 13.7826 0.562541C16.0346 1.34978 18.2801 2.14347 20.5322 2.93716C21.9905 3.45338 23.4488 3.96314 24.9071 4.47936C25.0233 4.51808 25.133 4.5568 25.2491 4.60842C25.5072 4.72457 25.7073 4.87944 25.7331 5.18271C25.7266 5.30532 25.7266 5.43437 25.7266 5.55698Z"
                          fill="#9B9B9B"
                        />
                        <path
                          d="M6.05886 10.8741C6.05886 10.4095 6.05886 9.93841 6.05886 9.47381C6.05886 9.20279 6.05886 9.2028 6.31051 9.28668C8.36249 10.0158 10.4145 10.7386 12.46 11.4742C13.0601 11.6871 13.6344 11.6871 14.2281 11.4742C16.2736 10.7386 18.332 10.0158 20.3775 9.28023C20.5647 9.20925 20.6356 9.2157 20.6292 9.448C20.6163 10.3707 20.6163 11.2999 20.6292 12.2227C20.6356 12.7131 20.4485 13.0938 20.0807 13.4035C19.4871 13.9004 18.7837 14.1714 18.0545 14.3844C16.0284 14.9909 13.9635 15.1458 11.8599 14.978C10.4338 14.8619 9.04003 14.6038 7.71076 14.0553C7.29133 13.881 6.89772 13.6681 6.55572 13.3648C6.22018 13.068 6.0395 12.7002 6.0524 12.242C6.06531 11.7904 6.05886 11.3322 6.05886 10.8741Z"
                          fill="#9B9B9B"
                        />
                        <path
                          d="M23.9849 8.00903C23.9849 9.20279 23.9849 10.3707 23.9849 11.5387C23.9849 12.3969 23.9914 13.2616 23.9785 14.1198C23.9785 14.2618 24.0172 14.3456 24.1333 14.436C24.9141 15.0425 24.9077 16.1653 24.114 16.7525C23.9978 16.8429 23.9785 16.9332 23.9785 17.0558C23.9785 18.1657 23.9849 19.2756 23.9785 20.3854C23.9785 20.7274 23.772 21.0049 23.4687 21.1017C23.1719 21.192 22.8557 21.0888 22.675 20.8436C22.5653 20.6952 22.5266 20.5274 22.5266 20.3467C22.5266 19.2497 22.5266 18.1592 22.5331 17.0623C22.5331 16.9203 22.5008 16.8364 22.3782 16.7461C21.6168 16.1718 21.6039 15.0425 22.3588 14.4618C22.5008 14.3521 22.5331 14.2489 22.5331 14.0811C22.5266 12.313 22.5331 10.545 22.5266 8.77046C22.5266 8.57042 22.5911 8.49944 22.7718 8.44137C23.1654 8.31231 23.5591 8.1639 23.9849 8.00903Z"
                          fill="#9B9B9B"
                        />
                      </svg>
                      <h6>Education</h6>
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-12 col-lg-6 col-md-6 test_one">
              <div className="box_test ex_box">
                <div className="row">
                  <div className="col-9 col-md-7">
                    <h4 className="mb-20">Ankle Scraping</h4>
                  </div>
                  <div className="col-3 col-md-5 text-right">
                   <div className="btn-group">
                          <div className="icon_bar" onClick={handleClick}>
                            <svg
                              width="37"
                              height="37"
                              viewBox="0 0 37 37"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <circle
                                cx="18.1211"
                                cy="18.3081"
                                r="18"
                                fill="black"
                              ></circle>
                              <rect
                                x="10.9209"
                                y="18.6885"
                                width="3.32275"
                                height="9.53105"
                                fill="#1AE074"
                              ></rect>
                              <rect
                                x="15.9927"
                                y="13.5295"
                                width="3.32275"
                                height="14.6901"
                                fill="#9B9B9B"
                              ></rect>
                              <rect
                                x="21.4141"
                                y="8.10806"
                                width="3.32275"
                                height="20.1114"
                                fill="#9B9B9B"
                              ></rect>
                            </svg>
                          </div>
                          <div
                            className={
                              isActive
                                ? "dropdown-menu dropdown-menu-right bar_dropdown show"
                                : "dropdown-menu dropdown-menu-right bar_dropdown"
                            }
                          >
                            <h4>Exercise Levels</h4>
                            <div id="wrapper1">
                              {/* <canvas id="barChart"></canvas> */}
                              <img src={barchart} alt="barchart" />
                              <div className="btn_chart text-center">
                                <Link
                                  to="/change-level"
                                  className="theme_btn1 small_btn"
                                >
                                  Change Level
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                  </div>
                </div>

                <h6>Starting Position</h6>

                <ul className="list_exs">
                  <li>
                    <svg
                      width="5"
                      height="7"
                      viewBox="0 0 5 7"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M1.3664 6.30592C1.56106 6.3217 1.759 6.25151 1.91295 6.11366C2.67397 5.45534 3.43498 4.79703 4.19588 4.13858C4.26788 4.07572 4.34371 4.01702 4.40498 3.93951C4.55839 3.75205 4.63816 3.48784 4.61802 3.23095C4.59964 2.97179 4.4807 2.72576 4.29994 2.57061C3.53116 1.90485 2.76216 1.23934 1.99294 0.574328C1.92795 0.518785 1.86481 0.459075 1.79281 0.41565C1.61927 0.308098 1.41028 0.279569 1.21978 0.337511C1.05259 0.38649 0.901481 0.501869 0.794359 0.657517C0.683846 0.817331 0.621039 1.02044 0.621258 1.22507C0.621039 2.60975 0.621039 3.99442 0.621258 5.37923C0.619835 5.54813 0.660321 5.7169 0.736258 5.86132C0.867342 6.11581 1.11036 6.28749 1.3664 6.30592Z"
                        fill="#FB1417"
                      />
                    </svg>
                    <p>
                      Lie on your side with your straight legs stacked few
                      inches in front of your body.
                    </p>
                  </li>
                  <li>
                    <svg
                      width="5"
                      height="7"
                      viewBox="0 0 5 7"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M1.3664 6.30592C1.56106 6.3217 1.759 6.25151 1.91295 6.11366C2.67397 5.45534 3.43498 4.79703 4.19588 4.13858C4.26788 4.07572 4.34371 4.01702 4.40498 3.93951C4.55839 3.75205 4.63816 3.48784 4.61802 3.23095C4.59964 2.97179 4.4807 2.72576 4.29994 2.57061C3.53116 1.90485 2.76216 1.23934 1.99294 0.574328C1.92795 0.518785 1.86481 0.459075 1.79281 0.41565C1.61927 0.308098 1.41028 0.279569 1.21978 0.337511C1.05259 0.38649 0.901481 0.501869 0.794359 0.657517C0.683846 0.817331 0.621039 1.02044 0.621258 1.22507C0.621039 2.60975 0.621039 3.99442 0.621258 5.37923C0.619835 5.54813 0.660321 5.7169 0.736258 5.86132C0.867342 6.11581 1.11036 6.28749 1.3664 6.30592Z"
                        fill="#FB1417"
                      />
                    </svg>
                    <p>
                      Your bottom arm should be straight out in front of you
                      with your plam on the floor.
                    </p>
                  </li>
                  <li>
                    <svg
                      width="5"
                      height="7"
                      viewBox="0 0 5 7"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M1.3664 6.30592C1.56106 6.3217 1.759 6.25151 1.91295 6.11366C2.67397 5.45534 3.43498 4.79703 4.19588 4.13858C4.26788 4.07572 4.34371 4.01702 4.40498 3.93951C4.55839 3.75205 4.63816 3.48784 4.61802 3.23095C4.59964 2.97179 4.4807 2.72576 4.29994 2.57061C3.53116 1.90485 2.76216 1.23934 1.99294 0.574328C1.92795 0.518785 1.86481 0.459075 1.79281 0.41565C1.61927 0.308098 1.41028 0.279569 1.21978 0.337511C1.05259 0.38649 0.901481 0.501869 0.794359 0.657517C0.683846 0.817331 0.621039 1.02044 0.621258 1.22507C0.621039 2.60975 0.621039 3.99442 0.621258 5.37923C0.619835 5.54813 0.660321 5.7169 0.736258 5.86132C0.867342 6.11581 1.11036 6.28749 1.3664 6.30592Z"
                        fill="#FB1417"
                      />
                    </svg>
                    <p>Place your top hand behind your head.</p>
                  </li>
                </ul>
                <h6>Movement</h6>
                <ul className="list_exs">
                  <li>
                    <svg
                      width="5"
                      height="7"
                      viewBox="0 0 5 7"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M1.3664 6.30592C1.56106 6.3217 1.759 6.25151 1.91295 6.11366C2.67397 5.45534 3.43498 4.79703 4.19588 4.13858C4.26788 4.07572 4.34371 4.01702 4.40498 3.93951C4.55839 3.75205 4.63816 3.48784 4.61802 3.23095C4.59964 2.97179 4.4807 2.72576 4.29994 2.57061C3.53116 1.90485 2.76216 1.23934 1.99294 0.574328C1.92795 0.518785 1.86481 0.459075 1.79281 0.41565C1.61927 0.308098 1.41028 0.279569 1.21978 0.337511C1.05259 0.38649 0.901481 0.501869 0.794359 0.657517C0.683846 0.817331 0.621039 1.02044 0.621258 1.22507C0.621039 2.60975 0.621039 3.99442 0.621258 5.37923C0.619835 5.54813 0.660321 5.7169 0.736258 5.86132C0.867342 6.11581 1.11036 6.28749 1.3664 6.30592Z"
                        fill="#FB1417"
                      />
                    </svg>
                    <p>
                      Pressing through your front palm and contracting your
                      oblique muscles, raise your legs and lift your rib cage
                      off the floor-focus on bringing your bottom rib to meet
                      your hip bone.
                    </p>
                  </li>
                  <li>
                    <svg
                      width="5"
                      height="7"
                      viewBox="0 0 5 7"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M1.3664 6.30592C1.56106 6.3217 1.759 6.25151 1.91295 6.11366C2.67397 5.45534 3.43498 4.79703 4.19588 4.13858C4.26788 4.07572 4.34371 4.01702 4.40498 3.93951C4.55839 3.75205 4.63816 3.48784 4.61802 3.23095C4.59964 2.97179 4.4807 2.72576 4.29994 2.57061C3.53116 1.90485 2.76216 1.23934 1.99294 0.574328C1.92795 0.518785 1.86481 0.459075 1.79281 0.41565C1.61927 0.308098 1.41028 0.279569 1.21978 0.337511C1.05259 0.38649 0.901481 0.501869 0.794359 0.657517C0.683846 0.817331 0.621039 1.02044 0.621258 1.22507C0.621039 2.60975 0.621039 3.99442 0.621258 5.37923C0.619835 5.54813 0.660321 5.7169 0.736258 5.86132C0.867342 6.11581 1.11036 6.28749 1.3664 6.30592Z"
                        fill="#FB1417"
                      />
                    </svg>
                    <p>Don’t use your neck.</p>
                  </li>
                  <li>
                    <svg
                      width="5"
                      height="7"
                      viewBox="0 0 5 7"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M1.3664 6.30592C1.56106 6.3217 1.759 6.25151 1.91295 6.11366C2.67397 5.45534 3.43498 4.79703 4.19588 4.13858C4.26788 4.07572 4.34371 4.01702 4.40498 3.93951C4.55839 3.75205 4.63816 3.48784 4.61802 3.23095C4.59964 2.97179 4.4807 2.72576 4.29994 2.57061C3.53116 1.90485 2.76216 1.23934 1.99294 0.574328C1.92795 0.518785 1.86481 0.459075 1.79281 0.41565C1.61927 0.308098 1.41028 0.279569 1.21978 0.337511C1.05259 0.38649 0.901481 0.501869 0.794359 0.657517C0.683846 0.817331 0.621039 1.02044 0.621258 1.22507C0.621039 2.60975 0.621039 3.99442 0.621258 5.37923C0.619835 5.54813 0.660321 5.7169 0.736258 5.86132C0.867342 6.11581 1.11036 6.28749 1.3664 6.30592Z"
                        fill="#FB1417"
                      />
                    </svg>
                    <p>Slowly lower back down to the starting position</p>
                  </li>
                </ul>
                <div className="exc_p">
                  <p>
                    <span>Note:</span> This exercise will help your right hip
                  </p>
                </div>
                <div className="border-t position-relative">
                  <Link
                    to="/assessment-timer3"
                    className="theme_btn1 puse mr-20"
                  >
                    Pause
                  </Link>
                  <Link to="/assessment-timer3" className="theme_btn1 next">
                    NEXT
                  </Link>
                </div>
                {/* <div class="timer"><strong id="timer">0 minutes 0 seconds</strong>
                      
                    </div> */}
                <div className="timer">
                  <strong id="timer">
                    {" "}
                    <Countdown
                      date={Date.now() + 60000}
                      renderer={renderer}
                    ></Countdown>
                  </strong>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default AssessmentTimer2;
