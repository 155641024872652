import React from 'react'
import { Link } from 'react-router-dom'
import Footer from '../Layout/Footer'
import Header from '../Layout/Header'

const Test3 = () => {
  return (
    <>
    <Header />
    <section className="welcome_001">
    <div className="container h-100">
        <div className="row h-100">
            <div className="col-12 col-lg-6 col-md-6 m-auto">
                <div className="box_banner_inner text-center">
                    <div>
                        <h1>Anterior Pelvic Tilt</h1>
                        <h5>Day 1</h5>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<section className="assestmentd1_001">
    <div className="container">
        <div className="row">
            <div className="col-12 col-lg-12 col-md-12 text-center">
                <h3>Daily Summary </h3>
            </div>
        </div>
    </div>
</section>
<section className="welcome_002">
    <div className="container">
        <div className="row align-items-center">
            <div className="col-12 col-lg-12 col-md-12">
                <ul className="breadcrumb main">
                    <li className=""><Link to="/">Home </Link></li>
                    <li><Link to="/welcome"> Welcome</Link></li>
                    <li><Link to="/assessment-gridview"> Assestment</Link></li>
                    <li><Link to="/assessment-timer4"> Day 1</Link></li>
                    <li className="active"><Link to="#"> Test</Link></li>
                </ul>
            </div>
        </div>
    </div>
</section>
<section className="test1_001">
    <div className="container">
        <div className="row">
            <div className="col-12 col-lg-6 col-md-6  ">
                <div className=" ssa box_video" style={{"textAlign":"center"}} data-click="0">
                    <video className="video" controls="controls" width="100%" height="100%" poster="images/poster.png">
                        <source src="images/ankle-scraping.mp4" />
                    </video>
                    <div id="video_controls_bar">
                        {/* <img src="../../images/puse.png" alt='puse' className="img-fluid img001" /> */}
                    </div>
                                        <div className="overlay_vid"></div>
                </div>
                <div className="education">

                    <Link to="../../images/ankle-scraping.mp4" className="edu popup-youtube">
                        <svg width="26" height="22" viewBox="0 0 26 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M25.7266 5.55698C25.5718 5.86026 25.3072 6.00867 24.991 6.11837C21.2355 7.44118 17.48 8.7769 13.7309 10.1126C13.4664 10.2094 13.2276 10.203 12.9566 10.1126C9.1624 8.75755 5.36172 7.41537 1.56104 6.0732C1.28357 5.97641 1.1029 5.79573 0.960938 5.56343C0.960938 5.42147 0.960938 5.27305 0.960938 5.13109C1.01256 4.87298 1.18678 4.71812 1.41908 4.61487C1.76753 4.46001 2.12889 4.35676 2.49024 4.22771C5.16814 3.2856 7.83958 2.3435 10.5175 1.40785C11.3176 1.13038 12.1113 0.846462 12.9114 0.562541C13.2083 0.459296 13.4922 0.459296 13.7826 0.562541C16.0346 1.34978 18.2801 2.14347 20.5322 2.93716C21.9905 3.45338 23.4488 3.96314 24.9071 4.47936C25.0233 4.51808 25.133 4.5568 25.2491 4.60842C25.5072 4.72457 25.7073 4.87944 25.7331 5.18271C25.7266 5.30532 25.7266 5.43437 25.7266 5.55698Z"
                                  fill="#9B9B9B"/>
                            <path d="M6.05886 10.8741C6.05886 10.4095 6.05886 9.93841 6.05886 9.47381C6.05886 9.20279 6.05886 9.2028 6.31051 9.28668C8.36249 10.0158 10.4145 10.7386 12.46 11.4742C13.0601 11.6871 13.6344 11.6871 14.2281 11.4742C16.2736 10.7386 18.332 10.0158 20.3775 9.28023C20.5647 9.20925 20.6356 9.2157 20.6292 9.448C20.6163 10.3707 20.6163 11.2999 20.6292 12.2227C20.6356 12.7131 20.4485 13.0938 20.0807 13.4035C19.4871 13.9004 18.7837 14.1714 18.0545 14.3844C16.0284 14.9909 13.9635 15.1458 11.8599 14.978C10.4338 14.8619 9.04003 14.6038 7.71076 14.0553C7.29133 13.881 6.89772 13.6681 6.55572 13.3648C6.22018 13.068 6.0395 12.7002 6.0524 12.242C6.06531 11.7904 6.05886 11.3322 6.05886 10.8741Z"
                                  fill="#9B9B9B"/>
                            <path d="M23.9849 8.00903C23.9849 9.20279 23.9849 10.3707 23.9849 11.5387C23.9849 12.3969 23.9914 13.2616 23.9785 14.1198C23.9785 14.2618 24.0172 14.3456 24.1333 14.436C24.9141 15.0425 24.9077 16.1653 24.114 16.7525C23.9978 16.8429 23.9785 16.9332 23.9785 17.0558C23.9785 18.1657 23.9849 19.2756 23.9785 20.3854C23.9785 20.7274 23.772 21.0049 23.4687 21.1017C23.1719 21.192 22.8557 21.0888 22.675 20.8436C22.5653 20.6952 22.5266 20.5274 22.5266 20.3467C22.5266 19.2497 22.5266 18.1592 22.5331 17.0623C22.5331 16.9203 22.5008 16.8364 22.3782 16.7461C21.6168 16.1718 21.6039 15.0425 22.3588 14.4618C22.5008 14.3521 22.5331 14.2489 22.5331 14.0811C22.5266 12.313 22.5331 10.545 22.5266 8.77046C22.5266 8.57042 22.5911 8.49944 22.7718 8.44137C23.1654 8.31231 23.5591 8.1639 23.9849 8.00903Z"
                                  fill="#9B9B9B"/>
                        </svg>
                        Education
                    </Link>
                </div>
            </div>
            <div className="col-12 col-lg-6 col-md-6 test_one">
                <div className="box_test">
                    <h4>Ankle Stretch</h4>
                    <form action="/assessment-test4">
                        <div className="form-group checkbox_custom">
                            <input type="checkbox" id="check1" />
                            <label for="check1">Can you easily place your palms on the ground behind you? </label>
                        </div>
                        <div className="form-group checkbox_custom">
                            <input type="checkbox" id="check2" />
                            <label for="check2">Do you feel the stretch in your quads or the front of your
                                ankles? </label>
                        </div>

                        <div className="border-t">
                                                    
                            <input type="submit" className="theme_btn1 black" value="next" />
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</section>
<Footer />
    </>
  )
}

export default Test3