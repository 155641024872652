import React, {useEffect} from 'react';
import ScrollTopOnMount from "../../Layout/ScrollTopOnMount";
import Header from "../../Layout/Header";
import Footer from "../../Layout/Footer";
import {Col, Container, Row} from "react-bootstrap";
import {connect} from "react-redux";
import {getEducationalVideos} from "../../../actions/user_program";

function Education({getEducationalVideos,educational_video}) {
    useEffect(() => {
        getEducationalVideos()

    }, [])
    return (
        <>
            <ScrollTopOnMount />
            <Header />
            <div className={"banner-recomended"}>
                <Container className={"h-100"}>
                    <Row className={"h-100"}>
                        <Col className={"col-12 col-lg-6 col-md-6 m-auto"}>
                            <div className="box_banner_inner text-center">
                                <h3>Educational Videos</h3>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className={"clearfix"}></div>
        <section className="videos">
            <div className="container">

                <div className="row">
                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 ">
                        <div className="videos_headings ">
                            <h2 className="detail_span">APT EDUCATION VIDEOS</h2>

                        </div>

                    </div>
                    {
                        educational_video && educational_video.map((data, key)=>{
                            if(data){
                                return(
                                    <div className="col-6 col-sm-6 col-md-6  col-lg-3 col-xl-3 " key={key}>

                                        <div className="test_videobox-education pt-4">
                                            <div className="check_test pb-2">


                                                <div className="vids">
                                                    <div className="video_new">
                                                        <div className="ssa video_testbox"  data-click="0">


                                                            <div className="video_test">
                                                                <video className="video"  controls="controls">
                                                                    <source src={data?.video} type="video/mp4"/>
                                                                </video>
                                                                <img src="images/puse.png" className="img-fluid img001"/>
                                                            </div>
                                                        </div>


                                                    </div>
                                                    <div className="video_testcontent">
                                                        <h6>Ankle Jam</h6>
                                                    </div>
                                                </div>

                                            </div>

                                        </div>


                                    </div>
                                )

                            }

                        })
                    }


                </div>
                <div className="row mt-4 mb-5">
                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 pt-5">
                        <div className="videos_headings_black ">
                            <h2 className="detail_span ">APT EXERCISE EDUCATIONAL VIDEOS IN PROGRAM
                            </h2>

                        </div>

                    </div>
                    <div className="col-6 col-sm-6 col-md-6 col-lg-3 col-xl-3">
                        <div className="test_videobox-education pt-4">
                            <div className="check_test pb-2">

                                <div className="video_new">
                                    <div className="ssa video_testbox"  data-click="0">

                                        <div className="video_test">
                                            <video className="video"  controls="controls">
                                                <source src="images/ankle-scraping.mp4" type="video/mp4"/>
                                            </video>
                                            <img src="images/puse.png" className="img-fluid img001"/>
                                        </div>
                                                                            </div>

                                </div>
                                <div className="video_testcontent">
                                    <h6>Tuck &amp; Roll</h6>
                                                             </div>

                            </div>
                        </div>
                    </div>
                    <div className="col-6 col-sm-6 col-md-6 col-lg-3 col-xl-3">
                        <div className="test_videobox-education pt-4">
                            <div className="check_test pb-2">

                                <div className="video_new">
                                    <div className="ssa video_testbox"  data-click="0">


                                        <div className="video_test">
                                            <video className="video"  controls="controls">
                                                <source src="images/ankle-scraping.mp4" type="video/mp4"/>
                                            </video>
                                            <img src="images/puse.png" className="img-fluid img001"/>
                                        </div>
                                                                            </div>

                                </div>
                                <div className="video_testcontent">
                                    <h6>Sitting Faber</h6>
                                                               </div>

                            </div>
                        </div>
                    </div>
                    <div className="col-6 col-sm-6 col-md-6 col-lg-3 col-xl-3">
                        <div className="test_videobox-education pt-4">
                            <div className="check_test pb-2">

                                <div className="video_new">
                                    <div className="ssa video_testbox"  data-click="0">

                                        <div className="video_test">
                                            <video className="video"  controls="controls">
                                                <source src="images/ankle-scraping.mp4" type="video/mp4"/>
                                            </video>
                                            <img src="images/puse.png" className="img-fluid img001"/>
                                        </div>
                                                                            </div>

                                </div>
                                <div className="video_testcontent">
                                    <h6>Supine Faber</h6>
                                                              </div>

                            </div>
                        </div>
                    </div>
                    <div className="col-6 col-sm-6 col-md-6 col-lg-3 col-xl-3">
                        <div className="test_videobox-education pt-4">
                            <div className="check_test pb-2">

                                <div className="video_new">
                                    <div className="ssa video_testbox"  data-click="0">

                                        <div className="video_test">
                                            <video className="video"  controls="controls">
                                                <source src="images/ankle-scraping.mp4" type="video/mp4"/>
                                            </video>
                                            <img src="images/puse.png" className="img-fluid img001"/>
                                        </div>
                                                                            </div>

                                </div>
                                <div className="video_testcontent">
                                    <h6>QL &amp; Oblique Length</h6>

                                </div>

                            </div>
                        </div>
                    </div>
                    <div className="col-6 col-sm-6 col-md-6 col-lg-3 col-xl-3">
                        <div className="test_videobox-education pt-4">
                            <div className="check_test pb-2">

                                <div className="video_new">
                                    <div className="ssa video_testbox"  data-click="0">

                                        <div className="video_test">
                                            <video className="video"  controls="controls">
                                                <source src="images/ankle-scraping.mp4" type="video/mp4"/>
                                            </video>
                                            <img src="images/puse.png" className="img-fluid img001"/>
                                        </div>
                                                                            </div>

                                </div>
                                <div className="video_testcontent">
                                    <h6>Knee Pull</h6>

                                </div>

                            </div>
                        </div>
                    </div>
                    <div className="col-6 col-sm-6 col-md-6 col-lg-3 col-xl-3">
                        <div className="test_videobox-education pt-4">
                            <div className="check_test pb-2">

                                <div className="video_new">
                                    <div className="ssa video_testbox"  data-click="0">

                                        <div className="video_test">
                                            <video className="video"  controls="controls">
                                                <source src="images/ankle-scraping.mp4" type="video/mp4"/>
                                            </video>
                                            <img src="images/puse.png" className="img-fluid img001"/>
                                        </div>
                                                                            </div>

                                </div>
                                <div className="video_testcontent">
                                    <h6>Single Leg Squat</h6>

                                </div>

                            </div>
                        </div>
                    </div>
                </div>

            </div>


        </section>
            <Footer />
        </>
            );
}

const mapStateToProps = state => {

    return {
        educational_video: state.programReducer.educational_video
    }
}
export default connect(mapStateToProps, {getEducationalVideos}) (Education)