import Modal from "react-bootstrap/Modal";
import React, {useEffect, useRef, useState} from "react";
import {Form} from "react-bootstrap";
import {connect} from "react-redux";
import {checkValidation} from "../../../utils/helpers";
import {SKIP_TEST_REASONS} from "../../../utils/constants";
import FadeLoader from "react-spinners/FadeLoader";
import {skipTest} from "../../../actions/user_test";
import usePrevious from "../../Layout/usePrevious";
import {useNavigate, useParams} from "react-router-dom";


const SkipTestModal = ({show, handleClose, skipTestRequest, skipTest, program}) => {
    let {id} = useParams();
    const [validationErrors, setValidationErrors] = useState({});
    const onSubmitHandler = async (e) => {
        e.preventDefault();
        let formData = new FormData(e.target);
        let validate = await checkValidation(Object.fromEntries(formData), ['reason'])
        setValidationErrors(validate)
        if (Object.keys(validate).length > 0) {
            return false;
        } else {
            formData.append('program_id', program.id)
            formData.append('program_test_id', id)
            skipTest(formData)
        }
    };
    return (

        <Modal
            show={show}
            onHide={handleClose}
            className="modal_test"
            id="skip"
            centered
        >
            <Modal.Body className="text-left">
                <div className="box_state">
                    <h4>Why you want to skip this exercise? </h4>
                    <Form onSubmit={onSubmitHandler}>
                        <div className="form-group checkbox_custom">
                            <input type="radio" id="pain" name="reason" value={SKIP_TEST_REASONS.PAINFUL}/>
                            <label htmlFor="pain">Painful</label>
                        </div>
                        <div className="form-group checkbox_custom">
                            <input type="radio" id="understand" name="reason"
                                   value={SKIP_TEST_REASONS.DONT_UNDERSTAND}/>
                            <label htmlFor="understand">I don't Understand</label>
                        </div>
                        <div className="form-group checkbox_custom">
                            <input type="radio" id="shortTime" name="reason" value={SKIP_TEST_REASONS.SHORT_TIME}/>
                            <label htmlFor="shortTime">Short on time</label>
                        </div>
                        <div className="form-group checkbox_custom">
                            <input type="radio" id="skipWeek" name="reason" value={SKIP_TEST_REASONS.SKIP_ONE_WEEK}/>
                            <label htmlFor="skipWeek">Skip the test for one week</label>
                        </div>
                        <div className="skip-test-error">
                            <span
                                className="show-error">{validationErrors['reason']}</span>
                        </div>

                        <div className="form-group">
                            <textarea className="form-control form-1" placeholder="Other" name="note"></textarea>
                        </div>
                        {skipTestRequest ?
                            <div className="loader_custom"><FadeLoader color={"#FB1417"} height={10}/></div> :
                            <Form.Control
                                type="submit"
                                value="Submit"
                                className="theme_btn1 small_btn"
                            />}
                    </Form>
                </div>
            </Modal.Body>
        </Modal>

    );
};

const mapStateToProps = state => ({
    program: state.programReducer.program,
    skipTestRequest: state.testReducer.skipTestRequest,
    skipTestSuccess: state.testReducer.skipTestSuccess,
});

export default connect(mapStateToProps, {skipTest})(SkipTestModal);
