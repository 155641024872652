import {
    SKIP_TEST, SKIP_TEST_FAILURE, SKIP_TEST_REQUEST,
    SUBMIT_TEST, SUBMIT_TEST_FAILURE,
    SUBMIT_TEST_REQUEST

} from '../actions/types';
import {SKIP_TEST_REASONS} from "../utils/constants";

const initialState = {};

function testReducer(state = initialState, action) {
    const {type, payload} = action;

    switch (type) {
        case SUBMIT_TEST_REQUEST:
            return {
                ...state,
                submitTestRequest: true,
                submitTestSuccess: false,
                submitTestFailure: false
            }
        case SUBMIT_TEST:
            return {
                ...state,
                submitTestRequest: false,
                submitTestSuccess: true,
                submitTestFailure: false
            }
        case SUBMIT_TEST_FAILURE:
            return {
                ...state,
                submitTestRequest: false,
                submitTestSuccess: false,
                submitTestFailure: true
            }
        case SKIP_TEST_REQUEST:
            return {
                ...state,
                skipTestRequest: true,
                skipTestSuccess: false,
                skipTestFailure: false
            }
        case SKIP_TEST:
            return {
                ...state,
                skipTestRequest: false,
                skipTestSuccess: true,
                skipTestFailure: false
            }
        case SKIP_TEST_FAILURE:
            return {
                ...state,
                skipTestRequest: false,
                skipTestSuccess: false,
                skipTestFailure: true
            }
        default:
            return state;
    }
}

export default testReducer;
