import React, {useEffect, useState} from 'react'
import {Link, useNavigate, useParams} from "react-router-dom";
import {connect} from "react-redux";
import {getProgram, getProgramTests} from "../../../actions/user_program";
import FadeLoader from "react-spinners/FadeLoader";
import {pluck} from "../../../utils/helpers";
import {setAlert} from "../../../actions/alert";


const TestVideos = ({getTestsRequest, program_tests, getProgramTests, setAlert}) => {
    const {id} = useParams();
    const navigate = useNavigate();
    let [selectedTests, setSelectedTests] = useState([]);
    let [selectAllChecked, setSelectAllChecked] = useState(false);
    useEffect(() => {
        getProgramTests(id)
    }, [])
    const handleSelectAll = async () => {
        setSelectAllChecked(!selectAllChecked)
        if (!selectAllChecked) {
            setSelectedTests(await pluck(program_tests, 'id'))
        } else {
            setSelectedTests([])
        }
    }
    const handleCheck = (e, id) => {
        if (e.target.checked && !selectedTests.includes(id)) {
            setSelectedTests([...selectedTests, id]);
        } else {
            setSelectedTests(selectedTests =>
                selectedTests.filter(item => {
                    return item !== id;
                })
            );
        }
    }
    const startTests = (e) => {
        e.preventDefault()
        if (selectedTests.length == 0) {
            setAlert('Please select atleast one test', 'danger')
        } else {
            navigate('/assessment-test/' + selectedTests[0], {state: {selectedTests}})
        }
    }
    console.log("program_tests:", program_tests)
    return (
        <>
            <section className="welcome_001">
                <div className="container h-100">
                    <div className="row h-100">
                        <div className="col-12 col-lg-6 col-md-6 m-auto">
                            <div className="box_banner_inner text-center">
                                <div className="mx-auto">
                                    <h1>Test Videos</h1>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="videos">
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 pt-5">
                            <div className="videos_headings text-center">
                                <h2 className="detail_span text-center">Choose the tests that you will perform
                                    daily</h2>
                                <h4>Start with the test that you struggled with the most during the assessment</h4>
                            </div>
                        </div>
                        {!program_tests ? <div className="loader_custom">
                            <FadeLoader color={"#FB1417"} height={10}/>
                        </div> : program_tests.map((item, index) => <div
                            className="col-6 col-sm-6 col-md-6  col-lg-4 col-xl-4 " key={index}>
                            <div className="test_videobox pt-4">
                                <div className="check_test pb-2">
                                    <input type="checkbox" id="check_test1" className="exercise" onChange={(e) => {
                                        handleCheck(e, item.id)
                                    }} checked={selectedTests.includes(item.id) ? true : false}/>
                                    <label className="" htmlFor="check_test1">
                                        <div className="vids">
                                            <div className="video_new">
                                                <div className="ssa video_testbox" style={{textAlign: "center"}}
                                                     data-click="0">
                                                    <video className="video" style={{width: '100%'}}
                                                           controls="controls" poster={item.image}>
                                                        <source src={item.video} type="video/mp4"/>
                                                    </video>
                                                </div>
                                            </div>
                                            <div className="video_testcontent">
                                                <h6>{item.question.title}</h6>
                                            </div>
                                        </div>
                                    </label>
                                </div>

                            </div>


                        </div>)}
                    </div>
                    {program_tests ?
                        <div class="test_btn text-center pb-5">
                            <Link to="#" class="theme_btn1 mt-30" data-target="#statuscheckout" onClick={startTests}>
                                Start Selected test</Link>
                            <button class="theme_btn1 mt-30 checkAll" onClick={handleSelectAll}>
                                {selectedTests.length > 0 ? "Unselect All" : "Select All"}
                            </button>
                        </div>
                        : null}
                </div>
            </section>
        </>
    )

}
const mapStateToProps = state => ({
    getTestsRequest: state.programReducer.getTestsRequest,
    getTestsSuccess: state.programReducer.getTestsSuccess,
    getTestsFailure: state.programReducer.getTestsFailure,
    program_tests: state.programReducer.program_tests,
});

export default connect(mapStateToProps, {getProgramTests, setAlert})(TestVideos);