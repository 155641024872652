import React from 'react'
import { Link } from "react-router-dom";
import left5 from "../../images/left5.png";
import left6 from "../../images/left6.png";


const BodyType3 = () => {
  return (
       <section className="bodytype_002  ">
        <div className="container ">
          <div className="row align-items-center">
            <div className="col-12 col-lg-3 col-md-3  text-center">
              <div className="img01">
                <img src={left5} alt="left5" className="img-fluid" />
              </div>
            </div>
            <div className="col-12 col-lg-6 col-md-6 text-center">
              <h2>Flat Back</h2>
              <h2 className="pain">Flat Back Pain</h2>
              <h4>Corrective Program</h4>
              <Link to="#" className="theme_btn1">
                click here
              </Link>
            </div>
            <div className="col-12 col-lg-3 col-md-3 text-center">
              <div className="img01">
                <img src={left6} alt="left6" className="img-fluid" />
              </div>
            </div>
          </div>
        </div>
      </section>
  )
}

export default BodyType3