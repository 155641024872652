import React from "react";

import Header from "./Header";
import Footer from "../../Layout/Footer";

import BodyType1 from "./BodyType1";
import BodyType2 from "./BodyType2";
import BodyType2Bg1 from "./BodyType2Bg1";
import BodyType3 from "./BodyType3";

const BodyType = () => {
  return (
    <div>
      <Header />
      <BodyType1 />
      <BodyType2 />
      <BodyType2Bg1 />
      <BodyType3 />
      <Footer />
    </div>
  );
};

export default BodyType;
