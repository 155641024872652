import {combineReducers} from 'redux';
import alert from './alert';
import auth from './auth';
import programReducer from "./user_program";
import testReducer from "./user_test";

export default combineReducers({
    alert,
    auth,
    programReducer,
    testReducer
});