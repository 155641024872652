import Modal from "react-bootstrap/Modal";
import React, {useEffect, useRef} from "react";
import {useState} from "react";
import {Form} from "react-bootstrap";
import {useNavigate, useParams} from "react-router-dom";
import {SORE_FEEDBACK} from "../../../utils/constants";
import {connect} from "react-redux";
import {checkPreviousDay, submitPreviousDayFeedback} from "../../../actions/user_program";
import FadeLoader from "react-spinners/FadeLoader";
import {checkValidation} from "../../../utils/helpers";
import {setAlert} from "../../../actions/alert";
import store from "../../../store";
import usePrevious from "../../Layout/usePrevious";

const StatusCheckinModal = ({weekSchedule, program, checkPreviousDay, previous_day, submitPreviousDayFeedback, submitCheckInRequest, submitCheckInSuccess, setAlert}) => {
    const navigate = useNavigate();
    let prevSubmitCheckInSuccess = usePrevious({submitCheckInSuccess})
    const [show, setShow] = useState(false);
    const [validationErrors, setValidationErrors] = useState({});
    const handleShow = () => {
        //let isFirstDay = weekSchedule && weekSchedule[0].days[0].meta.is_active ? 1 : 0
        if (previous_day) {
            setShow(true)
        } else {
            navigate(`/test-instructions/${program.id}`, {replace: true});
        }
    };
    const handleClose = () => setShow(false);
    const onSubmitHandler = async (e) => {
        e.preventDefault();
        let formData = new FormData(e.target);
        let validate = await checkValidation(Object.fromEntries(formData), ['good_sore', 'rating'])
        setValidationErrors(validate)
        if (Object.keys(validate).length > 0) {
            return false;
        }
        if (previous_day) {
            formData.append('user_program_day_id', previous_day.id)
            submitPreviousDayFeedback(formData)
        } else {
            setAlert('Already submitted', 'danger');
        }
    };
    useEffect(() => {
            if (localStorage.getItem('activeDayId')) {
                checkPreviousDay(localStorage.getItem('activeDayId'))
            }
        }, []
    )
    if (prevSubmitCheckInSuccess && !prevSubmitCheckInSuccess.submitCheckInSuccess && submitCheckInSuccess) {
        navigate(`/test-instructions/${program.id}`, {replace: true});
    }
    return (
        <>
            <div class="theme_btn1 mt-30" id="start-test" onClick={handleShow} style={{cursor: 'pointer'}}>
                Start
            </div>

            <Modal
                show={show}
                onHide={handleClose}
                className="status_checkout"
                centered
            >
                <Modal.Body>
                    <Modal.Header>
                        <Modal.Title>Status Check In</Modal.Title>
                        <button
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                            onClick={handleClose}
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </Modal.Header>
                    <div className="box_state">
                        <Form onSubmit={onSubmitHandler}>
                            <Form.Group className="form-group">
                                <Form.Label>How sore are you from previous workout?</Form.Label>
                                <ul className="rating">
                                    <li>
                                        <label className="container-checkbox11">
                                            <input type="radio" id="1" name="rating" value="1"/>
                                            <span className=" checkmark main_check1">
                                            <p>1</p>
                                        </span>
                                        </label>
                                    </li>
                                    <li>
                                        <label className="container-checkbox11">
                                            <input type="radio" id="2" name="rating" value="2"/>
                                            <span className=" checkmark main_check1">
                                            <p>2</p>
                                        </span>
                                        </label>
                                    </li>
                                    <li>
                                        <label className="container-checkbox11">
                                            <input type="radio" id="3" name="rating" value="3"/>
                                            <span className=" checkmark main_check1">
                                            <p>3</p>
                                        </span>
                                        </label>
                                    </li>
                                    <li>
                                        <label className="container-checkbox11">
                                            <input type="radio" id="4" name="rating" value="4"/>
                                            <span className=" checkmark main_check1">
                                            <p>4</p>
                                        </span>
                                        </label>
                                    </li>
                                    <li>
                                        <label className="container-checkbox11">
                                            <input type="radio" id="5" name="rating" value="5"/>
                                            <span className=" checkmark main_check1">
                                            <p>5</p>
                                        </span>
                                        </label>
                                    </li>
                                    <li>
                                        <label className="container-checkbox11">
                                            <input type="radio" id="6" name="rating" value="6"/>
                                            <span className=" checkmark main_check1">
                                            <p>6</p>
                                        </span>
                                        </label>
                                    </li>
                                    <li>
                                        <label className="container-checkbox11">
                                            <input type="radio" id="7" name="rating" value="7"/>
                                            <span className=" checkmark main_check1">
                                            <p>7</p>
                                        </span>
                                        </label>
                                    </li>
                                    <li>
                                        <label className="container-checkbox11">
                                            <input type="radio" id="8" name="rating" value="8"/>
                                            <span className=" checkmark main_check1">
                                            <p>8</p>
                                        </span>
                                        </label>
                                    </li>
                                    <li>
                                        <label className="container-checkbox11">
                                            <input type="radio" id="9" name="rating" value="9"/>
                                            <span className=" checkmark main_check1">
                                            <p>9</p>
                                        </span>
                                        </label>
                                    </li>
                                    <li>
                                        <label className="container-checkbox11">
                                            <input type="radio" id="10" name="rating" value="10"/>
                                            <span className=" checkmark main_check1">
                                            <p>10</p>
                                        </span>
                                        </label>
                                    </li>
                                </ul>
                                <div className="row">
                                    <div className="col-6 col-lg-6">
                                        <p className="status">Not at all</p>
                                    </div>
                                    <div className="col-6 col-lg-6">
                                        <p className="status text-right">Very</p>
                                    </div>
                                </div>
                                <span
                                    className="show-error">{validationErrors['rating']}</span>
                            </Form.Group>
                            <Form.Group className="form-group check_box">
                                <Form.Label>
                                    If sore, is it a good sore or painful sore?
                                </Form.Label>
                                <div className="row">
                                    <div className="col-6 col-lg-4">
                                        <div className="form-group checkbox_custom">
                                            <Form.Control type="radio" id="check1" name="good_sore"
                                                          value={SORE_FEEDBACK.GOOD}/>
                                            <Form.Label htmlFor="check1">Good </Form.Label>
                                        </div>
                                    </div>
                                    <div className="col-6 col-lg-4">
                                        <div className="form-group checkbox_custom">
                                            <Form.Control type="radio" id="check2" name="good_sore"
                                                          value={SORE_FEEDBACK.PAINFUL}/>
                                            <Form.Label htmlFor="check2">Painful </Form.Label>
                                        </div>
                                    </div>
                                    <span
                                        className="show-error">{validationErrors['good_sore']}</span>
                                </div>
                            </Form.Group>
                            <Form.Group className="form-group">
                                <Form.Label>If painful sore, please explain:</Form.Label>
                                <textarea className="form-control form-1" name="feedback"></textarea>
                            </Form.Group>
                            <Form.Group className="form-group text-center">
                                {submitCheckInRequest ? <div className="loader_custom">
                                        <FadeLoader color={"#FB1417"} height={10}/>
                                    </div> :
                                    <Form.Control
                                        type="submit"
                                        value="Continue"
                                        className="theme_btn1"
                                    />}
                            </Form.Group>
                        </Form>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};

const mapStateToProps = state => ({
    program: state.programReducer.program,
    previous_day: state.programReducer.previous_day,
    submitCheckInRequest: state.programReducer.submitCheckInRequest,
    submitCheckInSuccess: state.programReducer.submitCheckInSuccess,
    submitCheckInFailure: state.programReducer.submitCheckInFailure,
});

export default connect(mapStateToProps, {checkPreviousDay, submitPreviousDayFeedback, setAlert})(StatusCheckinModal);
