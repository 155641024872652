import React from 'react'
import { Link } from "react-router-dom";
import logo from "../../images/logo.png";
const Header = () => {
  return (
        <header className="header">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-12 col-lg-2 col-md-2 ">
              <div className="logo">
                <Link to="home-login.php">
                  {" "}
                  <img src={logo} alt="logo" className="img-fluid" />
                </Link>
              </div>
              <span className="icon_mobile">
                <i className="fas fa-bars"></i>
              </span>
            </div>
            <div className="col-12 col-lg-10 col-md-10 text-right hide_mobile">
              <span className="show_mobile close1">
                <i className="far fa-times-circle"></i>
              </span>
              <div className="main_head">
                <ul className="menu_top right_left">
                  <li>
                    <Link to="/" className="h_login">
                      {" "}
                      Home
                    </Link>
                  </li>
                  <li>
                    <Link to="#" className="h_login">
                      {" "}
                      About Us{" "}
                    </Link>
                  </li>
                  <li>
                    <Link to="/welcome" className="h_login">
                      {" "}
                      programs
                    </Link>
                  </li>
                  <li>
                    <Link to="#" className="h_login">
                      {" "}
                      Education
                    </Link>
                  </li>
                  <li>
                    <Link to="#" className="h_login">
                      {" "}
                      Pain Trigger points
                    </Link>
                  </li>
                </ul>
                <ul className="menu_top right_menu">
                  <li>
                    <Link to="/login" className="h_login">
                      {" "}
                      Sign In
                    </Link>
                  </li>

                  <li>
                    <Link to="/signup" className="btn-1">
                      Sign Up
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </header>
  )
}

export default Header